import { NextSeo, NextSeoProps } from 'next-seo'
import React from 'react'

interface IProps extends NextSeoProps {
  canonicalUri?: string
}

export const CustomNextSeo: React.FC<IProps> = (nextSeoProps) => (
  <NextSeo
    {...nextSeoProps}
    canonical={nextSeoProps.canonicalUri}
    openGraph={
      nextSeoProps.canonicalUri && {
        url: nextSeoProps.openGraph?.url ?? nextSeoProps.canonicalUri,
        title: nextSeoProps.openGraph?.title ?? nextSeoProps.title,
        description: nextSeoProps.openGraph?.description ?? nextSeoProps.description,
        images: nextSeoProps.openGraph?.images,
        // type: 'website',
        // locale: 'fr_FR',
        // site_name: 'OLY Be',
      }
    }
    // additionalLinkTags={[
    //   {
    //     rel: 'shortcut icon',
    //     href: `${Config.websiteUrl}/favicon.ico`,
    //   },
    // ]}
    robotsProps={{
      maxSnippet: -1,
      maxVideoPreview: -1,
      maxImagePreview: 'large',
    }}
    additionalMetaTags={[
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0',
      },
      {
        name: 'apple-itunes-app',
        content: 'app-id=1447522136',
      },
    ]}
    // twitter={{
    //   site: '@be_oly',
    //   cardType: 'summary',
    // }}
  />
)
