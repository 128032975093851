import { LinkProps } from 'next/link'
import { IntlShape } from 'react-intl'

import { IAutoBreadcrumbProps } from '@/components/_layout/AutoBreadcrumb'
import {
  buildDisciplineRoute,
  buildLessonExploreStudioRoute,
  buildLessonSessionRoute,
  buildPlaceRoute,
  buildPlacesRoute,
  routes,
} from '@/utils/route'

import { capitalize } from './text'

export interface IBreadCrumbItem {
  label: string
  url?: LinkProps['href']
}
type IBreadCrumbItemList = {
  [routePath: string]: (intl: IntlShape, args?: IAutoBreadcrumbProps) => IBreadCrumbItem[]
}

const routesAuth: IBreadCrumbItemList = {
  [routes.auth.passwordForgot]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mot de passe oublié',
        description: 'Auth PasswordForgot label',
      }),
    },
  ],
  [routes.auth.signin]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Se connecter',
        description: 'Auth Signin label',
      }),
    },
  ],
  [routes.auth.signup]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: "S'inscrire",
        description: 'Auth Signup label',
      }),
    },
  ],
}

const routesAtWork: IBreadCrumbItemList = {
  [routes.atwork.index]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'En entreprise',
        description: 'AtWork label',
      }),
    },
  ],
  [routes.atwork.myorg]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'En entreprise',
        description: 'AtWork label',
      }),
      url: routes.atwork.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mon espace',
        description: 'AtWork - MyOrg label',
      }),
    },
  ],
}

const routesBlog: IBreadCrumbItemList = {
  [routes.blog]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Notre blog',
        description: 'Blog label',
      }),
    },
  ],
  ['/blog/[prismicUid]']: (intl, { prismicDocument }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Notre blog',
        description: 'Blog label',
      }),
      url: routes.blog,
    },
    {
      label: prismicDocument.data.title,
    },
  ],
}

const routesBooking: IBreadCrumbItemList = {
  [routes.booking]: (intl, { lesson }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    ...(lesson.isB2B()
      ? [
          {
            label: intl.formatMessage({
              defaultMessage: 'En entreprise',
              description: 'AtWork myorg label',
            }),
            url: routes.atwork.myorg,
          },
        ]
      : lesson.isOnline()
        ? [
            {
              label: intl.formatMessage({
                defaultMessage: 'Pratiquer en ligne',
                description: 'Practice Online label',
              }),
              url: routes.practice.online.index,
            },
            {
              label: intl.formatMessage({
                defaultMessage: 'Planning cours live',
                description: 'Explore Live label',
              }),
              url: routes.explore.live,
            },
          ]
        : [
            {
              label: intl.formatMessage({
                defaultMessage: 'Pratiquer en studio',
                description: 'Practice Studio label',
              }),
              url: routes.practice.studio,
            },
            {
              label: intl.formatMessage(
                {
                  defaultMessage: 'Planning cours {lessonArea}',
                  description: 'Explore Studio label',
                },
                {
                  lessonArea: lesson.renderArea(),
                }
              ),
              url: buildLessonExploreStudioRoute(lesson.area),
            },
          ]),
    {
      label: lesson.title,
      url: buildLessonSessionRoute(lesson),
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Réservation',
        description: 'Booking label',
      }),
    },
  ],
  [routes.bookingInvite]: (intl, { lesson }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    ...(lesson.isB2B()
      ? [
          {
            label: intl.formatMessage({
              defaultMessage: 'En entreprise',
              description: 'AtWork myorg label',
            }),
            url: routes.atwork.myorg,
          },
        ]
      : [
          {
            label: intl.formatMessage({
              defaultMessage: 'Pratiquer en studio',
              description: 'Practice Studio label',
            }),
            url: routes.practice.studio,
          },
          {
            label: intl.formatMessage(
              {
                defaultMessage: 'Planning cours {lessonArea}',
                description: 'Explore Studio label',
              },
              {
                lessonArea: lesson.renderArea(),
              }
            ),
            url: buildLessonExploreStudioRoute(lesson.area),
          },
        ]),
    {
      label: lesson.title,
      url: buildLessonSessionRoute(lesson),
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Invitation',
        description: 'BookingInvite label',
      }),
    },
  ],
}

const routesDashboard: IBreadCrumbItemList = {
  [routes.dashboard]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Tableau de bord',
        description: 'Dashboard label',
      }),
    },
  ],
}

const routesExplore: IBreadCrumbItemList = {
  [routes.explore.discipline]: (intl, { discipline }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Disciplines',
        description: 'Explore Disciplines label',
      }),
      url: routes.explore.disciplines,
    },
    ...(discipline.parent?.parent
      ? [
          {
            label: discipline.parent.parent.title,
            url: buildDisciplineRoute(discipline.parent.parent),
          },
        ]
      : []),
    ...(discipline.parent
      ? [
          {
            label: discipline.parent.title,
            url: buildDisciplineRoute(discipline.parent),
          },
        ]
      : []),
    { label: discipline.title },
  ],
  [routes.explore.disciplines]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Nos disciplines',
        description: 'Explore Disciplines label',
      }),
    },
  ],
  [routes.explore.instructor]: (intl, { teacher }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Nos professeurs',
        description: 'Explore Instructors label',
      }),
      url: routes.explore.instructors,
    },
    {
      label: teacher.fullName,
    },
  ],
  [routes.explore.instructors]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Nos professeurs',
        description: 'Explore Instructors label',
      }),
    },
  ],
  [routes.explore.live]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en live & replay',
        description: 'Practice Online Live label',
      }),
      url: routes.practice.online.live,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Planning cours live',
        description: 'Explore Live label',
      }),
    },
  ],
  [routes.explore.replay]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en live & replay',
        description: 'Practice Online Live label',
      }),
      url: routes.practice.online.live,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Catalogue de replays',
        description: 'Explore Replay label',
      }),
    },
  ],
  [routes.explore.studio]: (intl, { area }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en studio',
        description: 'Practice Studio label',
      }),
      url: routes.practice.studio,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Planning cours {areaTitle}',
          description: 'Practice Online label',
        },
        {
          areaTitle: capitalize(area.title),
        }
      ),
    },
  ],
  [routes.explore.serie]: (intl, { serie }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Pratiquer en {isOnline, select, true {ligne} other {studio}}',
          description: 'Practice label',
        },
        { isOnline: serie.isOnline() }
      ),
      url: serie.isOnline() ? routes.practice.online.index : routes.practice.studio,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Cours de {serieTitle}',
          description: 'Explore serie label',
        },
        {
          serieTitle: serie.title,
        }
      ),
    },
  ],
  [routes.explore.place]: (intl, { place }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en studio',
        description: 'Practice Studio label',
      }),
      url: routes.practice.studio,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Nos studios à {area}',
          description: 'Practice Places area',
        },
        {
          area: place.renderArea(),
        }
      ),
      url: buildPlacesRoute(place.area),
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Planning du studio {placeTitle}',
          description: 'Explore Place label',
        },
        {
          placeTitle: place.title,
        }
      ),
    },
  ],
  [routes.explore.places]: (intl, { area }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en studio',
        description: 'Practice Studio label',
      }),
      url: routes.practice.studio,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Nos studio à {areaTitle}',
          description: 'Practice Places area',
        },
        {
          areaTitle: area.title,
        }
      ),
    },
  ],
}

const routesMe: IBreadCrumbItemList = {
  [routes.account.atwork]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mon espace entreprise',
        description: 'Account - AtWork label',
      }),
    },
  ],
  [routes.account.bookings]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes réservations',
        description: 'Account - Bookings label',
      }),
    },
  ],
  [routes.account.invoices]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes factures',
        description: 'Account - Invoices label',
      }),
    },
  ],
  [routes.account.prepaids]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes cartes cadeaux',
        description: 'Account - Prepaids label',
      }),
    },
  ],
  [routes.account.profile]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mon profil',
        description: 'Account - Profile label',
      }),
    },
  ],
  [routes.account.purchases]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes abonnements & cartes',
        description: 'Account - Purchases label',
      }),
    },
  ],
  [routes.account.referrals]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes parrainages',
        description: 'Account - Referral label',
      }),
    },
  ],
  [routes.account.achievements]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes accomplissements',
        description: 'Account - Achievements label',
      }),
    },
  ],
  [routes.account.unlimitedPlaylist]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes programmes en accès illimité',
        description: 'Account - UnlimitedPlaylist label',
      }),
    },
  ],
  [routes.account.favorites.index]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes favoris',
        description: 'Account - Favorite label',
      }),
    },
  ],
  [routes.account.favorites.series]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes favoris',
        description: 'Account - Favorite label',
      }),
      url: routes.account.favorites.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes cours favoris',
        description: 'Account - Favorites - Series label',
      }),
    },
  ],
  [routes.account.favorites.videos]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes favoris',
        description: 'Account - Favorite label',
      }),
      url: routes.account.favorites.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes vidéos favorites',
        description: 'Account - Favorites - Videos label',
      }),
    },
  ],
  [routes.account.favorites.videoGroups]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes favoris',
        description: 'Account - Favorite label',
      }),
      url: routes.account.favorites.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes programmes favoris',
        description: 'Account - Favorites - VideoGroups label',
      }),
    },
  ],
  [routes.account.favorites.replays]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes favoris',
        description: 'Account - Favorite label',
      }),
      url: routes.account.favorites.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes replays favoris',
        description: 'Account - Favorites - Replays label',
      }),
    },
  ],
  [routes.account.favorites.teachers]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes favoris',
        description: 'Account - Favorite label',
      }),
      url: routes.account.favorites.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes professeurs favoris',
        description: 'Account - Favorites - teachers label',
      }),
    },
  ],
  [routes.account.favorites.studios]: (intl: IntlShape) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes favoris',
        description: 'Account - Favorite label',
      }),
      url: routes.account.favorites.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Mes studios favoris',
        description: 'Account - Favorites - studios label',
      }),
    },
  ],
}

const routesOrder: IBreadCrumbItemList = {}

const routesPayment: IBreadCrumbItemList = {
  [routes.payment]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Achat',
        description: 'Payment label',
      }),
    },
  ],
}

const routesPlaylists: IBreadCrumbItemList = {
  [routes.playlist]: (intl, { videoGroup }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en vidéo',
        description: 'Videos label',
      }),
      url: routes.practice.online.videos,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Programmes vidéos',
        description: 'Playlists label',
      }),
      url: routes.playlists,
    },
    { label: videoGroup.title },
  ],
  [routes.playlists]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en vidéo',
        description: 'Videos label',
      }),
      url: routes.practice.online.videos,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Programmes vidéos',
        description: 'Playlists label',
      }),
    },
  ],
}

const routesPractice: IBreadCrumbItemList = {
  [routes.practice.studio]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en studio',
        description: 'Practice Studio label',
      }),
    },
  ],
  [routes.practice.studioArea]: (intl, { area }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en studio',
        description: 'Practice Studio label',
      }),
      url: routes.practice.studio,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'à {areaTitle}',
          description: 'Practice Studio area',
        },
        {
          areaTitle: capitalize(area.title),
        }
      ),
    },
  ],
  [routes.practice.online.index]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
    },
  ],
  [routes.practice.online.live]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en live et replay',
        description: 'Practice Online Live label',
      }),
    },
  ],
  [routes.practice.online.videos]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en videos',
        description: 'Practice Online Videos label',
      }),
    },
  ],
  [routes.practice.workshop]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Nos ateliers thématiques',
        description: 'Practice Workshop label',
      }),
    },
  ],
}

const routesPricing: IBreadCrumbItemList = {
  [routes.pricing.online]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Nos tarifs',
        description: 'Pricing Online label',
      }),
    },
  ],
  [routes.pricing.prepaid]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Nos cartes cadeaux',
        description: 'Pricing Prepaid label',
      }),
    },
  ],
  [routes.pricing.studio]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Nos tarifs',
        description: 'Pricing Studio label',
      }),
    },
  ],
}

const routesSession: IBreadCrumbItemList = {
  [routes.session.live]: (intl, { lesson }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en live & replay',
        description: 'Practice Online Live label',
      }),
      url: routes.practice.online.live,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Planning cours live',
        description: 'Explore Live label',
      }),
      url: routes.explore.live,
    },
    { label: lesson.title },
  ],
  [routes.session.replay]: (intl, { lesson }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en live & replay',
        description: 'Practice Online Live label',
      }),
      url: routes.practice.online.live,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Catalogue de replays',
        description: 'Explore Replay label',
      }),
      url: routes.explore.replay,
    },
    { label: lesson.title },
  ],
  [routes.session.studio]: (intl, { lesson }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en studio',
        description: 'Practice Studio label',
      }),
      url: routes.practice.studio,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Planning cours {lessonArea}',
          description: 'Explore Studio label',
        },
        {
          lessonArea: lesson.renderArea(),
        }
      ),
      url: buildLessonExploreStudioRoute(lesson.area),
    },
    ...(lesson.place
      ? [
          {
            label: intl.formatMessage(
              {
                defaultMessage: 'Planning {placeTitle}',
                description: 'Explore Studio place title',
              },
              {
                placeTitle: lesson.place.title,
              }
            ),
            url: buildPlaceRoute(lesson.place._id),
          },
        ]
      : []),
    { label: lesson.title },
  ],
}

const routesVideos: IBreadCrumbItemList = {
  [routes.video]: (intl, { video }) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en vidéo',
        description: 'Practice Online Videos label',
      }),
      url: routes.practice.online.videos,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Catalogue',
        description: 'Videos label',
      }),
      url: routes.videos,
    },
    { label: video.title },
  ],
  [routes.videos]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Pratiquer en ligne',
        description: 'Practice Online label',
      }),
      url: routes.practice.online.index,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Cours en vidéo',
        description: 'Practice Online Videos label',
      }),
      url: routes.practice.online.videos,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Catalogue',
        description: 'Videos label',
      }),
    },
  ],
}

const routesMisc: IBreadCrumbItemList = {
  [routes.about]: (intl) => [
    {
      label: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home label',
      }),
      url: '/',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Qui sommes-nous ?',
        description: 'About label',
      }),
    },
  ],
}

export const BREADCRUMB_BYPATH: IBreadCrumbItemList = {
  ...routesAuth,
  ...routesAtWork,
  ...routesBlog,
  ...routesBooking,
  ...routesDashboard,
  ...routesExplore,
  ...routesMe,
  ...routesOrder,
  ...routesPayment,
  ...routesPlaylists,
  ...routesPractice,
  ...routesPricing,
  ...routesSession,
  ...routesVideos,
  ...routesMisc,
}
