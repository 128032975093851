import { LinkProps } from 'next/link'
import { ParsedUrlQueryInput } from 'querystring'
import { UrlObject } from 'url'

import { Config } from '@/services/config'
import {
  ILesson,
  ILessonDecorated,
  IPriceDecorated,
  IPropertyValueDiscipline,
  ITeacher,
  IVideo,
  IVideoGroup,
} from '@/types/main'
import { AREA_DUAL_KEY } from '@/utils/constants'

export const routes = {
  auth: {
    passwordForgot: '/auth/password-forgot',
    passwordUpdate: '/auth/password-update',
    signin: '/auth/signin',
    signup: '/auth/signup',
    signout: '/auth/signout',
  },
  atwork: { index: '/atwork', myorg: '/atwork/myorg' },
  blog: '/blog',
  booking: '/booking/[uuid]',
  bookingInvite: '/booking-invite/[uuid]',
  dashboard: '/dashboard',
  explore: {
    discipline: '/explore/discipline/[[...disciplineSlugs]]',
    disciplines: '/explore/disciplines',
    instructor: '/explore/instructors/[teacherId]',
    instructors: '/explore/instructors',
    live: '/explore/online/live',
    replay: '/explore/online/replay',
    studio: '/explore/studio/[areaSlug]',
    serie: '/explore/serie/[serieUuid]',
    place: '/explore/place/[placeId]',
    places: '/explore/places/[areaSlug]',
  },
  account: {
    joinLive: '/me/join-live/[lessonUuid]',
    atwork: '/me/atwork',
    bookings: '/me/bookings',
    favorites: {
      index: '/me/favorites',
      teachers: '/me/favorites/teachers',
      replays: '/me/favorites/replays',
      series: '/me/favorites/series',
      studios: '/me/favorites/studios',
      videos: '/me/favorites/videos',
      videoGroups: '/me/favorites/playlists',
    },
    unlimitedPlaylist: '/me/unlimited-playlists',
    invoices: '/me/invoices',
    prepaids: '/me/prepaids',
    profile: '/me/profile',
    purchases: '/me/purchases',
    referrals: '/me/referrals',
    achievements: '/me/achievements',
  },
  order: '/order/[[...args]]',
  payment: '/payment/[uuid]',
  playlist: '/playlists/[videoGroupSlug]',
  playlists: '/playlists',
  practice: {
    studio: '/practice/studio',
    studioArea: '/practice/studio/[areaSlug]',
    online: {
      index: '/practice/online',
      live: '/practice/online/live',
      videos: '/practice/online/videos',
    },
    workshop: '/practice/workshop',
  },
  pricing: {
    online: '/pricing/online',
    studio: '/pricing/studio',
    prepaid: '/pricing/prepaid',
  },
  session: {
    live: '/session/live/[uuid]',
    replay: '/session/replay/[uuid]',
    studio: '/session/studio/[uuid]',
  },
  video: '/videos/[videoSlug]',
  videos: '/videos',
  about: '/about',
  onboarding: '/onboarding',
  survey: '/survey/[id]',
  cgu: '/cgu', // Prismic
  cgv: '/cgv', // Prismic
  faq: '/faq', // Prismic
  recruitmentTeacher: '/instructors-join', // Prismic
  privacy: '/politique-de-confidentialite', // Prismic
  cookiePolicy: '/politique-cookies', // Prismic
}

export const externalRoutes = {
  facebook: 'https://www.facebook.com/olybeyoga',
  instagram: 'https://www.instagram.com/oly.be/',
  linkedin: 'https://www.linkedin.com/company/oly-be/mycompany/',
  pinterest: 'https://www.pinterest.fr/olybeyoga/',
  newsletter:
    'https://31caebae.sibforms.com/serve/MUIEACCh6CGoX9zGX0zzSq9anSjyODB1CEThXGCkxTDEnHlsv5z-vsuJVhWQl3m8CleZy7jDIEvcEkHQ3jldVhbMe-M597eMBH3LI4i50QTkBQwEtjix7zlS_MOsyWAKL6Zv50-IERx6VF0Er0SX8jIURWe9dDSsRkS97aInoi8slka_eo0e6MUw93MOSG726wOVws32QOdisn4g',
  newsletterB2B:
    'https://31caebae.sibforms.com/serve/MUIEAD2EDLOhhjYutkmSN_IeElssr5O0EPmN_PmaZbX1pt26vn1s411H_z-YjFKuO16E6wltHUuWiXvmjVB-xxU0tua6xauRXyWS2azrcuGd1RhDwqoXXOueBKiLBcSEIiYyBj8Rpxes1cFpSE9tiPbTOx5FoHcQ0yV9NqgNCrejVusL7YP1g5oJE2VUNLK-EFFjXsjc6qd9QyA_',
  appleStore: 'https://apps.apple.com/app/apple-store/id1447522136',
  googleStore:
    'https://play.google.com/store/apps/details?id=com.olybe&referrer=utm_source%3Dwebsite%26utm_medium%3Dlink%26utm_campaign%3Dhome',
  wttj: 'https://www.welcometothejungle.com/fr/companies/oly-be',
}

export const configRoutes = {
  playlistFree: Config.routePlaylistFree,
}

export const buildLessonListingRoute = (
  lesson: ILessonDecorated,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] =>
  lesson.isB2B()
    ? {
        pathname: routes.atwork.myorg,
        query: {
          ...queryAdds,
        },
      }
    : buildLessonExploreRoute(lesson.area, queryAdds)

export const buildLessonExploreRoute = (
  areaSlug: string,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] =>
  areaSlug === AREA_DUAL_KEY.ONLINE
    ? buildLessonExploreOnlineRoute(queryAdds)
    : buildLessonExploreStudioRoute(areaSlug, queryAdds)

export const buildLessonExploreOnlineRoute = (
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.explore.live,
  query: {
    ...queryAdds,
  },
})

export const buildLessonExploreStudioRoute = (
  areaSlug: string,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.explore.studio,
  query: {
    areaSlug,
    ...queryAdds,
  },
})

export const buildSerieExploreRoute = (
  serieUuid: string,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.explore.serie,
  query: {
    serieUuid,
    ...queryAdds,
  },
})

export const buildPlacesRoute = (
  areaSlug: string,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.explore.places,
  query: {
    areaSlug,
    ...queryAdds,
  },
})

export const buildPlaceRoute = (
  placeId: number,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.explore.place,
  query: {
    placeId,
    ...queryAdds,
  },
})

export const buildPracticeStudioAreaRoute = (
  areaSlug: string,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.practice.studioArea,
  query: {
    areaSlug,
    ...queryAdds,
  },
})

export const buildLessonExploreReplayRoute = (
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.explore.replay,
  query: {
    ...queryAdds,
  },
})

export const buildLessonSessionRoute = (lesson: ILessonDecorated): LinkProps['href'] => {
  if (lesson.isOnline()) {
    if (lesson.replayAvailable) {
      return {
        pathname: routes.session.replay,
        query: {
          uuid: lesson.uuid,
        },
      }
    }

    return {
      pathname: routes.session.live,
      query: {
        uuid: lesson.uuid,
      },
    }
  }

  return {
    pathname: routes.session.studio,
    query: {
      uuid: lesson.uuid,
    },
  }
}

export const buildLessonBookingRoute = (lesson: ILessonDecorated): LinkProps['href'] => ({
  pathname: routes.booking,
  query: {
    uuid: lesson.uuid,
  },
})

export const buildPricingRoute = (price: IPriceDecorated): LinkProps['href'] =>
  price.subscription ? routes.pricing.online : routes.pricing.studio

export const buildOrderRoute = (
  price: IPriceDecorated,
  lesson?: ILesson
): LinkProps['href'] => ({
  pathname: routes.order,
  query: {
    args: [price.ulid, ...(lesson ? [lesson.uuid] : [])],
  },
})

export const buildVideoRoute = (
  video: IVideo,
  videoGroupUlid?: string
): LinkProps['href'] => ({
  pathname: routes.video,
  query: {
    videoSlug: video.slug,
    ...(videoGroupUlid && { videoGroupUlid }),
  },
})

export const buildPlaylistRoute = (
  videoGroup: IVideoGroup,
  queryAdds?: ParsedUrlQueryInput
): LinkProps['href'] => ({
  pathname: routes.playlist,
  query: {
    videoGroupSlug: videoGroup.slug,
    ...queryAdds,
  },
})

// Handle two possible formats depends on GQL query
export const buildDisciplineRoute = (
  discipline: Partial<IPropertyValueDiscipline | IPropertyValueDiscipline['practice']>
): LinkProps['href'] => {
  // Discipline is in fact a practice
  if ('pillar' in discipline) {
    return {
      pathname: routes.explore.discipline,
      query: {
        disciplineSlugs: [
          ...(discipline.parent ?? discipline.pillar
            ? [(discipline.parent ?? discipline.pillar).slug]
            : []),
          discipline.slug,
        ],
      },
    }
  }

  return {
    pathname: routes.explore.discipline,
    query: {
      disciplineSlugs: [
        ...(discipline.parent?.parent ?? discipline.practice?.pillar
          ? [(discipline.parent?.parent ?? discipline.practice.pillar).slug]
          : []),
        ...(discipline.parent ?? discipline.practice
          ? [(discipline.parent ?? discipline.practice).slug]
          : []),
        discipline.slug,
      ],
    },
  }
}

export const buildInstructorRoute = (teacher: Partial<ITeacher>): LinkProps['href'] => ({
  pathname: routes.explore.instructor,
  query: {
    teacherId: teacher._id,
  },
})

export const buildSurveyRoute = (lesson: Partial<ILesson>): LinkProps['href'] => ({
  pathname: routes.survey,
  query: {
    id: `lesson_${lesson.uuid}`,
  },
})

export const convertUrlObjectToString = (urlObject: UrlObject) => {
  const pathname = urlObject.pathname.replace(/\[(.*)\]/g, (match, param) => {
    const value = urlObject.query[param]
    delete urlObject.query[param]
    return value
  })

  if (!urlObject.query.length) {
    return pathname
  }

  return `${pathname}?${new URLSearchParams(urlObject.query as any).toString()}`
}
