export function readLocalStorage<T = unknown>(key: string): T | null {
  const value = localStorage.getItem(key)

  if (!value) return null

  try {
    return JSON.parse(value)
  } catch (err) {
    return null
  }
}

export function writeLocalStorage<T = unknown>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}

export function readSessionStorage<T = unknown>(key: string): T | null {
  const value = sessionStorage.getItem(key)

  if (!value) return null

  try {
    return JSON.parse(value)
  } catch (err) {
    return null
  }
}

export function writeSessionStorage<T = unknown>(key: string, value: T): void {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const removeSessionStorage = (key: string): void => {
  sessionStorage.removeItem(key)
}

export interface ICookieOptions {
  allowSubdomains?: boolean
  path?: string
  validDays?: number
}

export const readCookie = <T extends string>(key: string): T => {
  if (typeof document === 'undefined') {
    return
  }

  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === key ? decodeURIComponent(parts[1]) : r
  }, '') as T
}

export const writeCookie = (
  key: string,
  value: string,
  options: ICookieOptions = {}
): void => {
  const getExpirationDate = (days = 1): string =>
    new Date(Date.now() + days * 864e5).toUTCString()

  document.cookie = [
    `${key}=${encodeURIComponent(value)}`,
    `expires=${getExpirationDate(options.validDays)}`,
    `path=${options.path ?? '/'}`,
    ...(options.allowSubdomains ? [`domain=.${getCurrentDomain()}`] : []),
  ].join('; ')
}

export const removeCookie = (key: string, options: ICookieOptions = {}): void => {
  document.cookie = [
    `${key}= `,
    'max-age=-1',
    `path=${options.path ?? '/'}`,
    ...(options.allowSubdomains ? [`domain=.${getCurrentDomain()}`] : []),
  ].join('; ')
}

const getCurrentDomain = () =>
  !window.location.hostname.startsWith('localhost')
    ? window.location.hostname.split('.').slice(1).join('.')
    : window.location.hostname
