import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { Wisp } from '@/components/_layout/header/Wisp'
import { LogoLink } from '@/components/_shared/LogoLink'
import { CloseIcon } from '@/components/_svg/icons/CloseIcon'
import { MenuIcon } from '@/components/_svg/icons/MenuIcon'
import { DEVICE_WIDTH } from '@/utils/constants'

import { AuthStatus } from './AuthStatus'
import { Menu } from './Menu'

interface IProps {
  hideHeaderNav?: boolean
}

export const Header: React.FC<IProps> = ({ hideHeaderNav = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const route = useRouter()

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState)
  }

  useEffect(() => {
    setIsMenuOpen(false)
  }, [route])

  return (
    <StyledContainer $isMenuOpen={isMenuOpen}>
      <header>
        <GridContainer $hideHeaderNav={hideHeaderNav}>
          {!hideHeaderNav && (
            <MenuButton onClick={toggleMenu} aria-expanded={isMenuOpen}>
              <StyledCloseIcon $isMenuOpen={isMenuOpen} aria-hidden={!isMenuOpen} />
              <StyledMenuIcon $isMenuOpen={isMenuOpen} aria-hidden={isMenuOpen} />
            </MenuButton>
          )}
          <LogoLink />

          {!hideHeaderNav && (
            <RightContainer>
              <DesktopMenu />
              <AuthStatus />
              <Wisp />
            </RightContainer>
          )}
        </GridContainer>
        <MobileMenu $isMenuOpen={isMenuOpen} />
      </header>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)<{ $isMenuOpen: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  left: 0;
  overflow: hidden;
  position: ${({ $isMenuOpen }) => ($isMenuOpen ? 'fixed' : 'sticky')};
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.header};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    overflow: visible;
    position: relative;
    z-index: ${({ theme }) => theme.zIndex.headerDesktop};
  }
`
const GridContainer = styled.div<{ $hideHeaderNav: boolean }>`
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 0;
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    grid-template-columns: 1fr 4fr;
    padding: 20px 0;
  }
`
const MenuButton = styled.button`
  justify-content: center;
  width: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const StyledCloseIcon = styled(CloseIcon)<{ $isMenuOpen: boolean }>`
  display: ${({ $isMenuOpen }) => ($isMenuOpen ? 'block' : 'none')};
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
const StyledMenuIcon = styled(MenuIcon)<{ $isMenuOpen: boolean }>`
  display: ${({ $isMenuOpen }) => (!$isMenuOpen ? 'block' : 'none')};
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
const MobileMenu = styled(Menu)<{ $isMenuOpen: boolean }>`
  display: ${({ $isMenuOpen }) => ($isMenuOpen ? 'block' : 'none')};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const DesktopMenu = styled(Menu)`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: flex;
  }
`
const RightContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`
