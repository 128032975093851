import type { Content } from '@prismicio/client'
import { prismicClient } from 'prismicio'
import useSWR, { SWRConfiguration } from 'swr'

export const SWR_KEY__PRISMIC_MENU_RESULT = 'api_prismicMenu_result'

export const useSWRPrismicMenuResult = (
  prismicDocUID: string,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
) =>
  useSWR<Content.MenuDocument>(
    { apiKey: SWR_KEY__PRISMIC_MENU_RESULT, prismicDocUID },
    () => prismicClient.getByUID('menu', prismicDocUID),
    swrConfig
  )
