import { IUserAvatarFormValues } from '@/components/account/profile/AvatarForm'
import { IUserProfileFormValues } from '@/components/account/profile/ProfileForm'
import { IPasswordForgotFormValues } from '@/components/auth/PasswordForgotForm'
import { IPasswordUpdateFormValues } from '@/components/auth/PasswordUpdateForm'
import { ISignInFormValues } from '@/components/auth/SignInForm'
import { ISignUpFormValues } from '@/components/auth/SignUpForm'
import { IOnboarding, IUser } from '@/types/main'
import { fetchGetJSON, fetchPostJSON, fetchPutJSON, HTTPError } from '@/utils/api-helpers'
import { OPTINS_CONSENT_KEYS } from '@/utils/constants'

import { JWTPayload, signInFromStorage } from './auth'
import { Config } from './config'

export interface IUpdatePasswordResponse {
  message: 'ok' | 'ko'
}

export const authFetcher = async (): Promise<JWTPayload> => {
  try {
    return await signInFromStorage()
  } catch {
    throw new HTTPError(new Response(), null, 401)
  }
}

export interface ISignUpResponse {
  uuid: string
}

export const createUser = async (
  signUpFormValues: ISignUpFormValues
): Promise<ISignUpResponse> => {
  const consentValue = {
    nl: signUpFormValues.optinNl,
    misc: signUpFormValues.optinMisc,
    elle: signUpFormValues.optinElle,
  }
  const consentKeys = Object.entries(OPTINS_CONSENT_KEYS).reduce(
    (acc, [optin, cKeys]) => {
      if (!Config.elleIntegrationEnabled && optin === 'elle') {
        return acc
      }

      return {
        ...acc,
        ...cKeys.reduce(
          (acc1, consentKey) => ({
            ...acc1,
            [consentKey]: consentValue[optin],
          }),
          []
        ),
      }
    },
    {}
  )

  return await fetchPostJSON({
    uri: '/api/users',
    data: {
      email: signUpFormValues.email,
      password: signUpFormValues.password,
      firstName: signUpFormValues.firstName,
      lastName: signUpFormValues.lastName,
      phoneNumber: signUpFormValues.phoneNumber,
      initialAreaDualKey: signUpFormValues.areaDualKey,
      consentKeys: consentKeys,
      deviceInfos: {
        platform: 'web',
      },
    },
  })
}

export interface ILoginCheckResponse {
  token: string
  olybe_refresh_token?: string
  olybestaging_refresh_token?: string
  expires_in?: number
}
export const loginCheck = async (
  signInFormValues: ISignInFormValues
): Promise<ILoginCheckResponse> =>
  fetchPostJSON({
    uri: '/login_check',
    data: {
      username: signInFormValues.email,
      password: signInFormValues.password,
      deviceInfos: {
        platform: 'web',
      },
    },
    ignoreSignInRefreshOn401: true,
  })

export const resetPassword = async (
  passwordForgotFormValues: IPasswordForgotFormValues
): Promise<void> =>
  fetchPostJSON({
    uri: '/api/resetpassword',
    data: {
      email: passwordForgotFormValues.email,
    },
  })

export const udpatePassword = async (
  passwordUpdateFormValues: IPasswordUpdateFormValues
): Promise<IUpdatePasswordResponse> =>
  fetchPostJSON({
    uri: '/api/resetpassword/reset',
    data: {
      token: passwordUpdateFormValues.token,
      password: passwordUpdateFormValues.password,
      passwordConfirmation: passwordUpdateFormValues.passwordConfirmation,
    },
  })

export const refreshTokens = async (): Promise<ILoginCheckResponse> =>
  fetchPostJSON({
    uri: '/api/token/refresh',
    ignoreSignInRefreshOn401: true,
  })

export const invalidateToken = async (): Promise<any> =>
  fetchGetJSON({
    uri: '/api/token/invalidate',
    ignoreSignInRefreshOn401: true,
  })

export type IOAuthProvider = 'google' | 'elle'

export const oAuthInit = async (
  provider: IOAuthProvider
): Promise<{ auth_url: string }> =>
  fetchGetJSON({
    uri: `/oauth/${provider}/web`,
  })

export const updateUserProfile = async (
  userUuid: string,
  userProfileFormValue: IUserProfileFormValues
): Promise<IUser> =>
  fetchPutJSON({
    uri: `/api/users/${userUuid}`,
    data: {
      email: userProfileFormValue.email,
      firstName: userProfileFormValue.firstName,
      lastName: userProfileFormValue.lastName,
      phoneNumber: userProfileFormValue.phoneNumber,
      instagramUrl: userProfileFormValue.instagramUrl,
      address: userProfileFormValue.address,
      city: userProfileFormValue.city,
      postalCode: userProfileFormValue.postalCode,
    },
    authentication: 'required',
  })

export const updateUserAvatar = async (
  userUuid: string,
  userAvatarFormValue: IUserAvatarFormValues
): Promise<IUser> =>
  fetchPostJSON({
    uri: `/api/users/${userUuid}/avatar`,
    formData: {
      avatarFile: userAvatarFormValue.avatarFile[0],
    },
    customHeaders: {
      Accept: 'application/json',
    },
    authentication: 'required',
  })

export const updateUserOnboarding = async (
  userUuid: string,
  partialOnboarding: IOnboarding
): Promise<IUser> =>
  fetchPutJSON({
    uri: `/api/users/${userUuid}`,
    data: { partialOnboarding },
    authentication: 'required',
  })
