import type { Content } from '@prismicio/client'
import * as prismic from '@prismicio/client'
import React from 'react'
import styled from 'styled-components'

import { PrismicLink } from '@/components/_prismic/PrismicLink'
import { PrismicRichText } from '@/components/_prismic/PrismicRichText'
import { useSWRPrismicMenuResult } from '@/hooks/swr/useSwr-prismicMenuResult'
import { Config } from '@/services/config'
import { DEVICE_WIDTH } from '@/utils/constants'
import { fullHeight } from '@/utils/style'

import { MenuCta } from './MenuCta'
import { MenuItem } from './MenuItem'

interface IProps {
  className?: string
}

export const Menu: React.FC<IProps> = ({ className }) => {
  const { data: menuDoc } = useSWRPrismicMenuResult(
    Config.elleIntegrationEnabled ? 'menu_header_elle' : 'menu_header'
  )

  return (
    <Nav className={className}>
      {menuDoc?.data.slices.map(
        (slice: Content.MenuEntryListSlice, sectionIndex: number) => (
          <MenuItem title={slice.primary.section} key={`section${sectionIndex}`}>
            {Object.values(computeColumnsItems(slice.items)).map(
              (columnItems, colIndex) => (
                <SubMenuColumn key={`section${sectionIndex}-col${colIndex}`}>
                  {columnItems.map((item, index) => (
                    <React.Fragment
                      key={`section${sectionIndex}-col${colIndex}-${index}`}
                    >
                      <SubMenuTitleLink field={item.link}>
                        {item.linklabel}
                      </SubMenuTitleLink>

                      {prismic.isFilled.richText(item.sublinks) && (
                        <PrismicRichText field={item.sublinks} />
                      )}
                    </React.Fragment>
                  ))}
                </SubMenuColumn>
              )
            )}

            {slice.primary.cta && <MenuCta cta={slice.primary.cta} />}
          </MenuItem>
        )
      )}
    </Nav>
  )
}

const computeColumnsItems = (
  items: Content.MenuEntryListSliceDefaultItem[]
): { [colIndex: number]: Content.MenuEntryListSliceDefaultItem[] } =>
  items.reduce((acc, item) => {
    const colIndex = +item.styleNewColumn + ((Object.keys(acc).length || 1) - 1)
    return { ...acc, [colIndex]: [...(acc[colIndex] ?? []), item] }
  }, {})

const Nav = styled.nav`
  ${fullHeight.mobile};

  background: ${({ theme }) => theme.color.white};
  left: 0;
  overflow: auto;
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.ms}`};
  position: fixed;
  right: 0;
  white-space: nowrap;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    display: flex;
    height: inherit;
    justify-content: flex-end;
    overflow: none;
    padding: 0 ${({ theme }) => theme.spacing.l};
    position: static;
    white-space: pre-wrap;

    > *:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.xl};
    }
  }
`
const SubMenuColumn = styled.div`
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.ms};
  }

  & > ul {
    margin: ${({ theme }) => theme.spacing.s} 0 0;
    padding-left: 0;

    li {
      &::before {
        content: none;
      }

      a {
        color: ${({ theme }) => theme.color.grey};
        display: block;
        margin-bottom: 5px;

        &:hover {
          color: ${({ theme }) => theme.color.terracotaDark};
        }
      }
    }
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    padding: ${({ theme }) => theme.spacing.l};
  }
`
const SubMenuTitleLink = styled(PrismicLink)`
  display: block;
  font-size: 18px;
  line-height: 27px;

  &:hover {
    color: ${({ theme }) => theme.color.terracotaDark};
  }

  && {
    color: inherit;
  }
`
