import * as prismic from '@prismicio/client'
import * as prismicNext from '@prismicio/next'

import { Config } from '@/services/config'

import config from './slicemachine.config.json'

/**
 * The project's Prismic repository name.
 */
export const repositoryName =
  process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || config.repositoryName

/**
 * A list of Route Resolver objects that define how a document's `url` field
 * is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
const routes: prismic.ClientConfig['routes'] = [
  {
    type: 'menu',
    path: '/',
  },
  {
    type: 'blogpost',
    path: '/blog/:uid',
  },
]

export const linkResolver: prismic.LinkResolverFunction<string | null> = (doc) => {
  if (doc.type === 'page') {
    return doc.uid === 'home' ? '/' : `/${doc.uid}`
  }

  return null
}

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    // fetchOptions: {
    //   cache: process.env.NODE_ENV === 'production' ? 'force-cache' : 'no-store',
    //   next: { tags: ['prismic'] },
    // },
    ...config,
    accessToken: Config.prismicApiKey,
  })

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  })

  return client
}

export const prismicClient = createClient()
