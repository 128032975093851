import React from 'react'
import styled from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { BellIcon } from '@/components/_svg/icons/BellIcon'
import { DEVICE_WIDTH } from '@/utils/constants'

export const Wisp: React.FC = () => (
  <WispCta className="wisp" round outlined>
    <BellIcon />
  </WispCta>
)

const WispCta = styled(CustomButton)`
  background: ${({ theme }) => theme.color.white};
  border: 0;
  display: none;
  height: 20px;
  margin: 0 ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.xs}
    ${({ theme }) => theme.spacing.xs};
  min-height: 20px;
  min-width: 20px;
  width: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
  }

  & .wisp_count[style] {
    right: -${({ theme }) => theme.spacing.xs} !important;
    top: -${({ theme }) => theme.spacing.xxs} !important;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    border: 1px solid ${({ theme }) => theme.color.terracota};
    height: ${({ theme }) => theme.spacing.l};
    margin: 0 ${({ theme }) => theme.spacing.s} 0 ${({ theme }) => theme.spacing.s};
    min-height: ${({ theme }) => theme.spacing.l};
    min-width: ${({ theme }) => theme.spacing.l};
    padding: 10px 0 0;
    width: ${({ theme }) => theme.spacing.l};

    & .wisp_count[style] {
      right: -2px !important;
      top: -2px !important;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.terracotaDark};

      svg {
        stroke: ${({ theme }) => theme.color.white};
      }
    }
  }
`
