import type { Content, LinkField } from '@prismicio/client'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { Container } from '@/components/_layout/Container'
import { Copyright } from '@/components/_layout/footer/Copyright'
import { PrismicLink } from '@/components/_prismic/PrismicLink'
import { LogoLink } from '@/components/_shared/LogoLink'
import { NextImage } from '@/components/_shared/NextImage'
import { NextLink } from '@/components/_shared/NextLink'
import { FacebookIcon } from '@/components/_svg/icons/FacebookIcon'
import { InstagramIcon } from '@/components/_svg/icons/InstagramIcon'
import { LinkedinIcon } from '@/components/_svg/icons/LinkedinIcon'
import { PinterestIcon } from '@/components/_svg/icons/PinterestIcon'
import { useSWRPrismicMenuResult } from '@/hooks/swr/useSwr-prismicMenuResult'
import { DEVICE_WIDTH } from '@/utils/constants'
import { externalRoutes } from '@/utils/route'

export const Footer: React.FC = () => {
  const { data: menuDoc } = useSWRPrismicMenuResult('menu_footer')

  return (
    <Wrapper>
      <FooterContainer>
        <Nav>
          <Column>
            <Logo />
          </Column>

          {menuDoc?.data.slices.map(
            (slice: Content.MenuEntryListSlice, sectionIndex: number) => (
              <Column key={`section${sectionIndex}`}>
                <TitleColumn>{slice.primary.section}</TitleColumn>

                <LinkList>
                  {slice.items.map((item, index) => (
                    <li key={`section${sectionIndex}-${index}`}>
                      {(item.link as LinkField & { url: string }).url ===
                      'javascript: Cookiebot.renew()' ? (
                        <Link onClick={() => window.Cookiebot.renew()}>
                          {item.linklabel}
                        </Link>
                      ) : (
                        <Link field={item.link}>{item.linklabel}</Link>
                      )}
                    </li>
                  ))}
                </LinkList>
              </Column>
            )
          )}
        </Nav>

        <SideWrapper>
          <Title>
            <FormattedMessage
              defaultMessage="Téléchargez notre application"
              description="Footer: app stores title"
            />
          </Title>
          <FlexWrapper>
            <LinkExternal href={externalRoutes.appleStore}>
              <NextImage
                src={'other/store-apple.png'}
                alt="store Apple"
                width={160}
                height={48}
              />
            </LinkExternal>
            <LinkExternal href={externalRoutes.googleStore}>
              <NextImage
                src={'other/store-google.png'}
                alt="store Google"
                width={160}
                height={48}
              />
            </LinkExternal>
          </FlexWrapper>

          <Title>
            <FormattedMessage
              defaultMessage="Retrouvez-nous sur les réseaux"
              description="Footer: social network title"
            />
          </Title>
          <FlexWrapper>
            <LinkSocialNetwork round href={externalRoutes.instagram}>
              <InstagramIcon />
            </LinkSocialNetwork>
            <LinkSocialNetwork round href={externalRoutes.facebook}>
              <FacebookIcon />
            </LinkSocialNetwork>
            <LinkSocialNetwork round href={externalRoutes.pinterest}>
              <PinterestIcon />
            </LinkSocialNetwork>
            <LinkSocialNetwork round href={externalRoutes.linkedin}>
              <LinkedinIcon />
            </LinkSocialNetwork>
          </FlexWrapper>

          <Title>
            <FormattedMessage
              defaultMessage="Inscrivez-vous à notre Newsletter"
              description="Footer: newsletter title"
            />
          </Title>
          <FlexWrapper>
            <NLButtonLink href={externalRoutes.newsletter} outlined>
              <FormattedMessage
                defaultMessage="Je m'inscris !"
                description="Footer: newsletter button"
              />
            </NLButtonLink>
          </FlexWrapper>
        </SideWrapper>
      </FooterContainer>
      <Copyright />
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.color.greyLighter};
  margin-top: ${({ theme }) => theme.spacing.ms};
  padding-top: 60px;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`
const FooterContainer = styled(Container)`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: flex;
    gap: ${({ theme }) => theme.spacing.s};
    justify-content: space-between;
  }
`
const Nav = styled.nav`
  margin-bottom: 50px;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: flex;
    flex: 1;
    gap: ${({ theme }) => theme.spacing.s};
    justify-content: space-between;
  }
`
const SideWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.m} 0;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-top: 0;
  }
`
const Column = styled.div`
  width: 100%;
`
const Logo = styled(LogoLink)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`
const Title = styled.p`
  ${({ theme }) => theme.typo.bold}

  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.s};
`
const TitleColumn = styled.p`
  ${({ theme }) => theme.typo.bold}

  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.s};
`
const LinkList = styled.ul`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.s};
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: block;
  }
`
const Link = styled(PrismicLink)`
  ${({ theme }) => theme.typo.link}

  && {
    color: inherit;
  }
`
const LinkExternal = styled(NextLink).attrs(() => ({
  rel: 'external nofollow',
  target: '_blank',
  prefetch: false,
}))`
  ${({ theme }) => theme.typo.link}

  && {
    color: inherit;
  }
`
const LinkSocialNetwork = styled(CustomButtonLink).attrs(() => ({
  rel: 'external nofollow',
  target: '_blank',
  prefetch: false,
}))``
const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing.s};
  margin-bottom: ${({ theme }) => theme.spacing.m};
`
const NLButtonLink = styled(CustomButtonLink).attrs(() => ({
  target: '_blank',
}))`
  display: block;
`
