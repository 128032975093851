import {
  PrismicNextLink as BasePrismicNextLink,
  PrismicNextLinkProps,
} from '@prismicio/next'
import { PrismicLink as BasePrismicLink, PrismicLinkProps } from '@prismicio/react'
import { linkResolver } from 'prismicio'

import { Link } from './style'

export const PrismicInternalLink: React.FC<React.PropsWithChildren<any>> = ({
  children,
  ...props
}) => <Link {...props}>{children}</Link>
export const PrismicExternalLink: React.FC<React.PropsWithChildren<any>> = ({
  children,
  ...props
}) => (
  <Link
    {...(props.href.startsWith('https://www.olybe.com') ||
    props.href.startsWith('https://olybe.elle.fr')
      ? {
          ...props,
          href: props.href.substring(21),
          target: props.target,
          rel: props.rel,
        }
      : props)}
  >
    {children}
  </Link>
)

export const PrismicLink: React.FC<any> = (props) => (
  <BasePrismicLink
    linkResolver={linkResolver}
    internalComponent={PrismicInternalLink}
    externalComponent={PrismicExternalLink}
    {...props}
  />
)

export const PrismicNextLink: React.FC<PrismicNextLinkProps> = (props) => (
  <BasePrismicNextLink linkResolver={linkResolver} {...props} />
)
