import { ImgixURLParams } from 'imgix-url-builder'
import Image, { ImageProps } from 'next/image'
import React from 'react'
import styled from 'styled-components'

import { imgixLoader } from '@/utils/image'

type IImgixPreconfig =
  | 'roundCrop'
  | 'roundCropFace'
  | 'capsuleCropFace'
  | 'capsuleCrop2'
  | 'capsuleCrop3'
  | 'capsuleCrop4'
  | 'videoCrop'
  | 'videoCropTrim'
  | 'placeCrop'
  | 'promoCrop'

const IMGIX_PRECONFIG_MAP = new Map<IImgixPreconfig, ImgixURLParams>([
  [
    'roundCrop',
    {
      mask: 'ellipse',
      fit: 'crop',
      ar: '1:1',
    },
  ],
  [
    'roundCropFace',
    {
      mask: 'ellipse',
      fit: 'crop',
      crop: ['faces'],
      ar: '1:1',
    },
  ],
  [
    'capsuleCropFace',
    {
      fit: 'crop',
      mask: 'corners',
      crop: ['faces'],
      cornerRadius: 400,
      ar: '8:11',
    },
  ],
  [
    'capsuleCrop2',
    {
      fit: 'crop',
      mask: 'corners',
      cornerRadius: 400,
      ar: '2:3',
    },
  ],
  [
    'capsuleCrop3',
    {
      fit: 'crop',
      mask: 'corners',
      cornerRadius: 400,
      ar: '2:1',
    },
  ],
  [
    'capsuleCrop4',
    {
      fit: 'crop',
      mask: 'corners',
      cornerRadius: 100,
      ar: '3:2',
    },
  ],
  [
    'videoCrop',
    {
      fit: 'crop',
      ar: '3:2',
    },
  ],
  [
    'videoCropTrim',
    {
      fit: 'crop',
      trim: 'auto',
      ar: '3:2',
    },
  ],
  [
    'placeCrop',
    {
      fit: 'crop',
      ar: '4:3',
    },
  ],
  [
    'promoCrop',
    {
      fit: 'crop',
      crop: ['faces'],
      ar: '3:1',
    },
  ],
])

type IProps = ImageProps & {
  objectFitCover?: boolean
  imgixPreconfig?: IImgixPreconfig
  fallbackCmp?: JSX.Element
}

export const NextImage: React.FC<IProps> = ({
  objectFitCover = false,
  imgixPreconfig,
  fallbackCmp,
  ...imageProps
}) => {
  if (!imageProps.src) {
    return fallbackCmp ?? null
  }

  const imgixParams: ImgixURLParams = {
    auto: ['compress', 'format'],
    ...(imgixPreconfig && IMGIX_PRECONFIG_MAP.get(imgixPreconfig)),
  }

  return (
    <Image
      loader={(props) => imgixLoader(props, imgixParams)}
      // {...(imageProps.width && {
      //   // Auto set render as responsive
      //   style: {
      //     height: 'auto',
      //     width: '100%',
      //   },
      // })}
      {...(objectFitCover && {
        style: {
          objectFit: 'cover',
        },
      })}
      {...imageProps}
      src={imageProps.src}
      alt={imageProps.alt}
    />
  )
}

export const NextImageAvatar: React.FC<IProps> = (props) => (
  <NextImage
    {...props}
    imgixPreconfig="roundCropFace"
    fallbackCmp={<StyledPlaceholderAvatar />}
    fill
    objectFitCover
  />
)
export const NextImageAvatar2: React.FC<IProps & { userInitials: string }> = ({
  userInitials,
  ...otherProps
}) => (
  <NextImage
    {...otherProps}
    imgixPreconfig="roundCrop"
    fallbackCmp={<StyledPlaceholderAvatar>{userInitials}</StyledPlaceholderAvatar>}
    fill
    objectFitCover
  />
)

const StyledPlaceholderAvatar = styled.div`
  align-items: center;
  aspect-ratio: 1;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.color.terracota} 0%,
    ${({ theme }) => theme.color.beigeLight} 100%
  );
  border-radius: 50%;
  color: ${({ theme }) => theme.color.greyDark};
  display: flex;
  font-size: 1.8rem;
  font-weight: bold;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
  user-select: none;
  width: 100%;

  @supports not (aspect-ratio: 1) {
    padding-top: 100%;
  }
`
