import React from 'react'
import styled from 'styled-components'

import { AutoBreadcrumb } from './AutoBreadcrumb'
import { AutoSeo } from './AutoSeo'
import { Footer } from './footer/Footer'
import { Header } from './header/Header'

interface IProps {
  hideHeaderNav?: boolean
  hideFooter?: boolean
  disableAutoSeo?: boolean
  disableAutoBreadcrumb?: boolean
}

export const MainLayout: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  hideHeaderNav = false,
  hideFooter = false,
  disableAutoSeo = false,
  disableAutoBreadcrumb = false,
}) => (
  <Wrapper id="main_layout">
    <Header hideHeaderNav={hideHeaderNav} />

    <Main>
      {!disableAutoSeo && <AutoSeo />}
      {!disableAutoBreadcrumb && <AutoBreadcrumb />}

      {children}
    </Main>

    {!hideFooter && <Footer />}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow-x: hidden;
`
const Main = styled.main`
  flex-grow: 2;
`
