import { css } from 'styled-components'

export const getLinearShadow = (
  width: string,
  height: string,
  x: string,
  y: string
) => css`
  position: relative;

  &&::before {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(${({ theme }) => theme.color.terracota}),
      to(${({ theme }) => theme.color.beige})
    );
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color.terracota} 0%,
      ${({ theme }) => theme.color.beige} 100%
    );
    border-radius: 50%;
    content: '';
    display: block;
    filter: blur(50px);
    height: ${height};
    left: ${x};
    position: absolute;
    top: ${y};
    width: ${width};
    z-index: -1;
  }
`

export const styledLinearShadow = css`
  position: relative;

  &&::before {
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.color.terracota} 0%,
      ${({ theme }) => theme.color.beige} 100%
    );
    content: '';
    display: block;
    filter: blur(50px);
    height: 100%;
    left: 20%;
    position: absolute;
    width: 65%; //70%;
    z-index: -1;
  }
`

export const fullHeight = {
  mobile: css`
    height: calc(100vh - 52px);
  `,
  desktop: css`
    height: calc(100vh - 111px - 34px);
  `,
}

export const showNbItems = (nbItems: false | number) => {
  if (nbItems === false) {
    return
  }

  return css`
    &::after {
      align-items: center;
      background-color: ${({ theme }) =>
        nbItems > 0 ? theme.color.terracota : theme.color.white};
      border-radius: ${({ theme }) => theme.spacing.m};
      color: ${({ theme }) => theme.color.white};
      content: '${nbItems}';
      display: inline-flex;
      height: ${({ theme }) => theme.spacing.m};
      justify-content: center;
      margin-left: ${({ theme }) => theme.spacing.xs};
      width: ${({ theme }) => theme.spacing.m};
    }
  `
}
