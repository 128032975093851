import React from 'react'
import styled from 'styled-components'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const LeftChevronIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="23"
    height="42"
    viewBox="0 0 23 42"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M20.0276 41.2499C19.7314 41.2516 19.4379 41.1949 19.1638 41.0829C18.8896 40.9709 18.6403 40.806 18.4301 40.5974L2.02756 24.1724C1.18943 23.3293 0.718994 22.1888 0.718994 20.9999C0.718994 19.8111 1.18943 18.6706 2.02756 17.8274L18.4301 1.40243C18.8537 0.978746 19.4284 0.740723 20.0276 0.740723C20.6267 0.740723 21.2014 0.978746 21.6251 1.40243C22.0487 1.82611 22.2868 2.40075 22.2868 2.99993C22.2868 3.59911 22.0487 4.17375 21.6251 4.59743L5.53756 20.6849C5.49391 20.725 5.45907 20.7737 5.43523 20.8279C5.41139 20.8821 5.39908 20.9407 5.39908 20.9999C5.39908 21.0592 5.41139 21.1177 5.43523 21.172C5.45907 21.2262 5.49391 21.2749 5.53756 21.3149L21.6251 37.4024C21.8359 37.6116 22.0033 37.8604 22.1176 38.1346C22.2318 38.4088 22.2906 38.7029 22.2906 38.9999C22.2906 39.297 22.2318 39.591 22.1176 39.8652C22.0033 40.1394 21.8359 40.3883 21.6251 40.5974C21.4148 40.806 21.1655 40.9709 20.8913 41.0829C20.6172 41.1949 20.3237 41.2516 20.0276 41.2499Z" />
  </svg>
)

export const RightChevronIcon = styled(LeftChevronIcon)`
  transform: rotate(180deg);
`
export const DownChevronIcon = styled(LeftChevronIcon)`
  transform: rotate(-90deg);
`
export const UpChevronIcon = styled(LeftChevronIcon)`
  transform: rotate(90deg);
`
