import '../app.css'
import 'plyr/dist/plyr.css'

import { GoogleTagManager } from '@next/third-parties/google'
import { PrismicPreview } from '@prismicio/next'
// import localFont from '@next/font/local'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { LogoJsonLd } from 'next-seo'
import { repositoryName } from 'prismicio'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { SWRConfig, unstable_serialize } from 'swr'

import { MainLayout } from '@/components/_layout/MainLayout'
import { SWR_KEY__PRISMIC_MENU_RESULT } from '@/hooks/swr/useSwr-prismicMenuResult'
import { Config } from '@/services/config'
import { HTTPError } from '@/utils/api-helpers'
import { imgUrl } from '@/utils/image'
import { cacheDebugMiddleware } from '@/utils/swr'

import intlFr from '../compiled-lang/fr.json'
import GlobalCSS from '../global.css'
import cachePrismicMenuFooter from '../swr-cache/prismicMenuFooter.json'
import cachePrismicMenuHeader from '../swr-cache/prismicMenuHeader.json'
import { theme } from '../theme'

// const playfairDisplayFont = localFont({
//   path: '../public/fonts/PlayfairDisplay-Medium.woff2',
//   style: 'normal',
//   display: 'swap',
//   variable: '--playfairdisplay-medium',
// })

// const interSemiBoldFont = localFont({
//   path: '../public/fonts/Inter-SemiBold.woff2',
//   weight: 'normal',
//   display: 'swap',
//   variable: '--inter-semibold',
// })

// const interRegularFont = localFont({
//   path: '../public/fonts/Inter-Regular.woff2',
//   weight: 'normal',
//   display: 'swap',
//   variable: '--inter-regular',
// })

const INTL_MESSAGES = {
  fr: intlFr,
}

const SWR_CACHES = {
  [unstable_serialize({
    apiKey: SWR_KEY__PRISMIC_MENU_RESULT,
    prismicDocUID: 'menu_header',
  })]: cachePrismicMenuHeader,
  [unstable_serialize({
    apiKey: SWR_KEY__PRISMIC_MENU_RESULT,
    prismicDocUID: 'menu_footer',
  })]: cachePrismicMenuFooter,
}

export type INextPageWithLayout<P = unknown> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type IAppPropsWithLayout = AppProps & {
  Component: INextPageWithLayout
  pageProps: { swrFallback?: [] }
  err: Error
}

const MyApp: INextPageWithLayout<IAppPropsWithLayout> = ({
  Component,
  pageProps,
  err,
}) => {
  const { locale = 'en', defaultLocale } = useRouter()

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  const getLayout = Component.getLayout ?? DefaultLayout

  return (
    <>
      <GoogleTagManager gtmId={Config.googleTagManagerId} />

      <LogoJsonLd
        logo={imgUrl('logo/olybe-logo-black.png', { w: 200 })}
        url={Config.websiteUrl}
      />

      <StyledThemeProvider theme={theme}>
        {/* <style jsx global>{`
          :root {
            ${playfairDisplayFont.variable};
            ${interSemiBoldFont.variable};
            ${interRegularFont.variable};
          }
        `}</style> */}
        <GlobalCSS />
        <SWRConfig
          value={{
            fallback: {
              ...SWR_CACHES,
              ...(pageProps.swrFallback ?? {}),
            },
            shouldRetryOnError: (error: Error) =>
              error instanceof HTTPError && error.status >= 500,
            // use: [cacheDebugMiddleware],
          }}
        >
          <IntlProvider
            locale={locale}
            defaultLocale={defaultLocale}
            messages={INTL_MESSAGES[locale]}
          >
            {getLayout(<Component {...pageProps} err={err} />)}
            <PrismicPreview repositoryName={repositoryName} />
          </IntlProvider>
        </SWRConfig>
      </StyledThemeProvider>
    </>
  )
}

const DefaultLayout = (page: ReactElement) => <MainLayout>{page}</MainLayout>

// eslint-disable-next-line import/no-default-export
export default MyApp
