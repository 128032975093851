import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const MenuIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M37.6849 5.17505H2.31494C1.71821 5.17505 1.14591 4.938 0.723952 4.51604C0.301995 4.09408 0.0649414 3.52179 0.0649414 2.92505C0.0649414 2.32831 0.301995 1.75602 0.723952 1.33406C1.14591 0.912102 1.71821 0.675049 2.31494 0.675049H37.6849C38.2817 0.675049 38.854 0.912102 39.2759 1.33406C39.6979 1.75602 39.9349 2.32831 39.9349 2.92505C39.9349 3.52179 39.6979 4.09408 39.2759 4.51604C38.854 4.938 38.2817 5.17505 37.6849 5.17505Z" />
    <path d="M37.6849 20.25H2.31494C1.71821 20.25 1.14591 20.0129 0.723952 19.591C0.301995 19.169 0.0649414 18.5967 0.0649414 18C0.0649414 17.4033 0.301995 16.831 0.723952 16.409C1.14591 15.987 1.71821 15.75 2.31494 15.75H37.6849C38.2817 15.75 38.854 15.987 39.2759 16.409C39.6979 16.831 39.9349 17.4033 39.9349 18C39.9349 18.5967 39.6979 19.169 39.2759 19.591C38.854 20.0129 38.2817 20.25 37.6849 20.25Z" />
    <path d="M19.9999 35.3249H2.31494C1.71821 35.3249 1.14591 35.0879 0.723952 34.6659C0.301995 34.244 0.0649414 33.6717 0.0649414 33.0749C0.0649414 32.4782 0.301995 31.9059 0.723952 31.4839C1.14591 31.062 1.71821 30.8249 2.31494 30.8249H19.9999C20.5967 30.8249 21.169 31.062 21.5909 31.4839C22.0129 31.9059 22.2499 32.4782 22.2499 33.0749C22.2499 33.6717 22.0129 34.244 21.5909 34.6659C21.169 35.0879 20.5967 35.3249 19.9999 35.3249Z" />
  </svg>
)
