import React from 'react'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'

export interface IContainerProps {
  scope?: 'primary' | 'secondary'
  className?: string
}

export const Container: React.FC<React.PropsWithChildren<IContainerProps>> = ({
  children,
  scope,
  className,
}) => (
  <Wrapper data-testid={scope && `loading-${scope}`} className={className}>
    {children}
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 20px;
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding: 0 ${({ theme }) => theme.spacing.ms};
  }
`
