import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const PinterestIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={theme.color.white}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M12.0007 0.881943C5.8608 0.881943 0.881958 5.85947 0.881958 12.0007C0.881958 16.552 3.6198 20.4638 7.53828 22.1832C7.5069 21.4079 7.53305 20.4744 7.73048 19.6298C7.9449 18.7275 9.16085 13.5722 9.16085 13.5722C9.16085 13.5722 8.80652 12.8623 8.80652 11.8123C8.80652 10.165 9.76228 8.93464 10.9508 8.93464C11.9614 8.93464 12.4504 9.69428 12.4504 10.6042C12.4504 11.6202 11.8019 13.1407 11.4686 14.5489C11.1901 15.7296 12.0595 16.6906 13.2231 16.6906C15.3294 16.6906 16.7481 13.9854 16.7481 10.7795C16.7481 8.34366 15.1072 6.51974 12.1223 6.51974C8.75031 6.51974 6.64921 9.034 6.64921 11.8425C6.64921 12.8126 6.93423 13.495 7.38138 14.0233C7.58796 14.2677 7.61542 14.3646 7.5409 14.6443C7.4886 14.847 7.3657 15.3412 7.3147 15.5361C7.24017 15.8172 7.01268 15.9191 6.75903 15.8145C5.20445 15.1804 4.48142 13.4794 4.48142 11.5666C4.48142 8.40903 7.14473 4.61999 12.4269 4.61999C16.6723 4.61999 19.4663 7.69385 19.4663 10.99C19.4663 15.3516 17.0423 18.6099 13.4664 18.6099C12.2674 18.6099 11.1377 17.9614 10.7507 17.2253C10.7507 17.2253 10.1048 19.7866 9.96886 20.2808C9.73352 21.1373 9.27198 21.9949 8.84967 22.6618C9.84989 22.9573 10.9063 23.1181 12.0007 23.1181C18.1405 23.1181 23.1181 18.1405 23.1181 12.0007C23.1181 5.85947 18.1405 0.881943 12.0007 0.881943Z" />
  </svg>
)
