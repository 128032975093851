import React, { ComponentPropsWithoutRef } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'
import { theme } from 'theme'

import { INextLinkProps, NextLink } from '@/components/_shared/NextLink'

interface IButtonProps {
  color?: string
  outlined?: boolean
  round?: boolean
  small?: boolean
}

export interface IButtonLinkProps extends IButtonProps, Omit<INextLinkProps, 'as'> {
  className?: string
}

export const CustomButton: React.FC<
  React.PropsWithChildren<IButtonProps & ComponentPropsWithoutRef<'button'>>
> = ({ children, color, outlined = false, round = false, small = false, ...props }) => (
  <StyledButton
    $color={color}
    $outlined={outlined}
    $round={round}
    $small={small}
    {...props}
  >
    {children}
  </StyledButton>
)

export const CustomButtonLink: React.FC<React.PropsWithChildren<IButtonLinkProps>> = ({
  children,
  color,
  outlined = false,
  round = false,
  small = false,
  ...props
}) => (
  <StyledButtonLink
    $color={color}
    $outlined={outlined}
    $round={round}
    $small={small}
    {...props}
  >
    {children}
  </StyledButtonLink>
)

const getDarkColor = (color: string) => {
  switch (color) {
    case theme.color.mandarine:
      return theme.color.mandarineDark
    case theme.color.black:
      return theme.color.grey
    case theme.color.pink:
      return theme.color.pinkDark
    default:
      return theme.color.terracotaDark
  }
}

const getLightColor = (color: string) => {
  switch (color) {
    case theme.color.mandarine:
      return theme.color.mandarineLight
    case theme.color.black:
      return theme.color.greyLight
    default:
      return theme.color.terracotaLight
  }
}

const getTypoColor = (color: string, outlined: boolean) => {
  if (outlined) {
    return color ?? theme.color.terracota
  }

  switch (color) {
    case theme.color.beigeLight:
      return theme.color.black
    case theme.color.white:
      return theme.color.terracota
    default:
      return theme.color.white
  }
}

const getBgColor = (color: string, outlined: boolean) => {
  if (outlined) {
    return theme.color.white
  }

  return color ?? theme.color.terracota
}

export const getButtonStyle = (
  theme: DefaultTheme,
  $color?: string,
  $outlined?: boolean,
  $round?: boolean,
  $small?: boolean
) => css`
  background-color: ${getBgColor($color, $outlined)};
  border: 1px solid ${$color ?? theme.color.terracota};
  border-radius: ${({ theme }) => ($small ? theme.spacing.s : theme.spacing.ms)};
  color: ${getTypoColor($color, $outlined)};
  cursor: pointer;
  display: flex;
  font-family: 'Inter Regular', sans-serif;
  gap: ${({ theme }) => theme.spacing.s};
  justify-content: center;
  padding: ${({ theme }) =>
    $small ? `6px ${theme.spacing.s}` : `12px ${theme.spacing.m}`};
  text-align: center;
  transition: all 300ms ease;

  ${$round &&
  css`
    align-items: center;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    padding: 0;
    width: 48px;
  `}

  &:hover {
    background-color: ${getDarkColor($color)};
    border-color: ${getDarkColor($color)};
    color: ${theme.color.white};
    text-decoration: none;
  }

  &:disabled {
    background-color: ${getLightColor($color)};
    border-color: ${getLightColor($color)};
    color: ${theme.color.white};
    cursor: inherit;
  }

  & > svg {
    transition: all 300ms ease;
  }
`

const StyledButtonLink = styled(NextLink)<{
  $color?: string
  $outlined?: boolean
  $round?: boolean
  $small?: boolean
}>`
  ${({ theme, $color, $outlined, $round, $small }) =>
    getButtonStyle(theme, $color, $outlined, $round, $small)};
`
const StyledButton = styled.button<{
  $color?: string
  $outlined?: boolean
  $round?: boolean
  $small?: boolean
}>`
  ${({ theme, $color, $outlined, $round, $small }) =>
    getButtonStyle(theme, $color, $outlined, $round, $small)};
`
