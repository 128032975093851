import baseSlugify from 'slugify'

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
export const fixedEncodeURIComponent = (str: string): string =>
  encodeURIComponent(str).replace(/[!'()*]/g, (c) => '%' + c.charCodeAt(0).toString(16))

export const slugify = (str: string) =>
  baseSlugify(str, {
    // remove: /[*+~.()'"!:@]/g,
    lower: true,
    strict: true,
    locale: 'fr',
  })

export const removeEmojis = (input: string) =>
  input
    .replace(
      /[\u2700-\u27bf]|[\ud83c\udc00-\ud83c\udfff]|[\ud83d\udc00-\ud83d\udfff]|[\ud83e\udc00-\ud83e\udfff]|[\u2600-\u26ff]|\ufe0f|\ufe0e/gu,
      ''
    )
    // .replace(/[\p{Emoji}]/gu, '')
    .replace(/\s+/g, ' ')
    .trim()
