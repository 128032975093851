import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const BellIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    width="25"
    height="50"
    viewBox="0 0 25 50"
    fill="none"
    stroke={theme.color.terracota}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
  </svg>
)
