import React from 'react'
import styled from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'
import { DEVICE_WIDTH } from '@/utils/constants'

import { NextImage } from './NextImage'

interface IProps {
  className?: string
}

export const LogoLink: React.FC<IProps> = ({ className }) => {
  return (
    <Wrapper href="/" className={className}>
      <NextImage
        src={'logo/olybe-logo-black.png'}
        alt="logo OLY Be"
        sizes={`(max-width: ${DEVICE_WIDTH.MOBILE}px) 37px, 80px`}
        fill
      />
    </Wrapper>
  )
}

const Wrapper = styled(NextLink)`
  display: block;
  height: 33px;
  line-height: 0;
  margin: 0 auto;
  position: relative;
  width: 37px;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    height: 71px;
    margin: 0 0 0 ${({ theme }) => theme.spacing.l};
    width: 80px;
  }
`
