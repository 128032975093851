import { PrismicNextImage } from '@prismicio/next'
import {
  PrismicRichText as BasePrismicRichText,
  PrismicRichTextProps,
} from '@prismicio/react'

import { slugify } from '@/utils/text'

import { PrismicExternalLink, PrismicInternalLink } from './PrismicLink'
import {
  Bold,
  CenteredImageWrapper,
  Embed,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Link,
  List,
  ListItem,
  OList,
  OListItem,
  Paragraph,
} from './style'

const defaultComponents = {
  heading2: ({ children, text }) => <Heading2 id={slugify(text)}>{children}</Heading2>,
  heading3: ({ children }) => <Heading3>{children}</Heading3>,
  heading4: ({ children }) => <Heading4>{children}</Heading4>,
  heading5: ({ children }) => <Heading5>{children}</Heading5>,
  paragraph: ({ children }) => <Paragraph>{children}</Paragraph>,
  strong: ({ children }) => <Bold>{children}</Bold>,
  listItem: ({ children }) => <ListItem>{children}</ListItem>,
  list: ({ children }) => <List>{children}</List>,
  oListItem: ({ children }) => <OListItem>{children}</OListItem>,
  oList: ({ children }) => <OList>{children}</OList>,
  image: ({ node }) => (
    <CenteredImageWrapper>
      {node.linkTo ? (
        <Link
          href={
            node.linkTo.url.startsWith('https://www.olybe.com') ||
            node.linkTo.url.startsWith('https://olybe.elle.fr')
              ? node.linkTo.url.substring(21)
              : node.linkTo.url
          }
        >
          <PrismicNextImage field={node} />
        </Link>
      ) : (
        <PrismicNextImage field={node} />
      )}
    </CenteredImageWrapper>
  ),
  embed: ({ node, key }) => (
    <Embed
      key={key}
      data-oembed={node.oembed.embed_url}
      data-oembed-type={node.oembed.type}
      data-oembed-provider={node.oembed.provider_name}
      dangerouslySetInnerHTML={{ __html: node.oembed.html ?? '' }}
    />
  ),
}

export const PrismicRichText: React.FC<PrismicRichTextProps> = ({
  components,
  ...props
}) => (
  <BasePrismicRichText
    components={{ ...defaultComponents, ...components }}
    internalLinkComponent={PrismicInternalLink}
    externalLinkComponent={PrismicExternalLink}
    {...props}
  />
)
