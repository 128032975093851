import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { DownChevronIcon } from '@/components/_svg/icons/ChevronIcon'
import { useOnClickOutside } from '@/hooks/use-onClickOutside'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  title: string
}

export const MenuItem: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  title,
}) => {
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false)
  const menuItemRef = useRef(null)
  useOnClickOutside(menuItemRef, () => setSubMenuIsOpen(false))
  const route = useRouter()

  const toggleSubMenu = () => {
    setSubMenuIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    setSubMenuIsOpen(false)
  }, [route])

  return (
    <NavLi ref={menuItemRef}>
      <Title $isActive={subMenuIsOpen} onClick={toggleSubMenu}>
        {title}
        <StyledDownChevronIcon $isActive={subMenuIsOpen} />
      </Title>

      <SubMenuWrapper $isOpen={subMenuIsOpen}>{children}</SubMenuWrapper>
    </NavLi>
  )
}

const NavLi = styled.li`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.ms} 0;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    padding: 0;
  }
`
const Title = styled.button<{ $isActive: boolean }>`
  ${({ theme }) => theme.typo.p};

  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: flex-start;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  transition: all 300ms;

  &:hover {
    text-decoration: none;

    &::after {
      background-color: ${({ theme }) => theme.color.pinkDark};
    }
  }

  &::before {
    background-color: ${({ theme }) => theme.color.pinkDark};
    border-radius: ${({ theme }) => theme.spacing.xs};
    content: '';
    display: block;
    height: ${({ theme }) => theme.spacing.xs};
    margin-right: ${({ theme }) => theme.spacing.s};
    transition: background-color 300ms;
    width: ${({ theme }) => theme.spacing.xs};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    color: ${({ theme }) => theme.color.greyDarker};
    padding: 20px 0;
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.color.terracotaDark};
    }

    &::before {
      display: none;
    }

    &::after {
      background-color: ${({ theme, $isActive }) =>
        $isActive ? theme.color.pinkDark : theme.color.white};
      border-radius: 10px;
      bottom: 0;
      content: '';
      display: block;
      height: ${({ theme }) => theme.spacing.xs};
      left: calc(50% - ${({ theme }) => theme.spacing.xxs});
      margin-top: ${({ theme }) => theme.spacing.ml};
      position: absolute;
      transition: background-color 300ms;
      width: ${({ theme }) => theme.spacing.xs};
    }
  }
`
const StyledDownChevronIcon = styled(DownChevronIcon)<{ $isActive: boolean }>`
  height: ${({ theme }) => theme.spacing.s};
  margin-left: auto;
  margin-right: 0;
  transform: ${({ $isActive }) => `rotate(${$isActive ? '90deg' : '270deg'}) `};
  transition: all 300ms;
  width: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const SubMenuWrapper = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.ms};
  padding-left: ${({ theme }) => theme.spacing.ms};

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.ms};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.greyLighter};
    border-radius: ${({ theme }) => theme.spacing.ml};
    box-shadow: 0 4px 12px rgb(0 0 0 / 3%);
    flex-direction: row;
    margin-top: 0;
    position: absolute;
    right: 8.3%;
    top: 110px;
  }
`
