/* eslint-disable @typescript-eslint/ban-types */
export const deepCopy = (object: object): object => JSON.parse(JSON.stringify(object))

export const isSame = (object1: object, object2: object): boolean =>
  JSON.stringify(object1) === JSON.stringify(object2)

export const isValidUUIDv4 = (input: string) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(input)

export const isSSR = typeof window === 'undefined'

export const handleSmoothScrollTo = (toDomId: string) => (e: React.MouseEvent) => {
  e.preventDefault()
  document.getElementById(toDomId).scrollIntoView({ behavior: 'smooth' })
}
