import { createHash } from 'crypto'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { NextLink } from '@/components/_shared/NextLink'
import { UserIcon } from '@/components/_svg/icons/UserIcon'
import { useSWRAuth } from '@/hooks/swr/useSwr-auth'
import { DEVICE_WIDTH } from '@/utils/constants'
import { routes } from '@/utils/route'

const sha256 = (content: string) => {
  return createHash('sha256').update(content).digest('hex')
}

export const AuthStatus: React.FC = () => {
  const { payload } = useSWRAuth()

  if (payload?.uuid) {
    return (
      <>
        <MobileButton href={routes.account.bookings}>
          <StyledUserIcon />
        </MobileButton>
        <DesktopButton href={routes.account.bookings} title={payload.email}>
          <FormattedMessage
            defaultMessage="Mon compte"
            description="AuthStatus: MyAccount link"
          />
        </DesktopButton>

        <GtmVariables
          id="auth-infos"
          data-user-id={payload.uuid}
          data-user-sha={sha256(payload.email)}
          data-user-subscriber={payload.subscriber}
          data-user-b2b={payload.b2b}
        />
      </>
    )
  }

  return (
    <>
      <MobileButton href={routes.auth.signin}>
        <StyledUserIcon />
      </MobileButton>
      <DesktopButton href={routes.auth.signin}>
        <FormattedMessage
          defaultMessage="Connexion"
          description="AuthStatus: SignIn link"
        />
      </DesktopButton>
    </>
  )
}

const MobileButton = styled(NextLink)`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const DesktopButton = styled(CustomButtonLink)`
  && {
    display: none;

    @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
      display: block;
      margin-right: ${({ theme }) => theme.spacing.s};
    }
  }
`
const StyledUserIcon = styled(UserIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: 20px;
  width: 20px;
`
const GtmVariables = styled.div`
  display: none;
`
