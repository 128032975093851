import { createGlobalStyle } from 'styled-components'

// eslint-disable-next-line import/no-default-export
export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1,h2,h3,h4,h5,h6 {
    font-size: 100%;
    font-weight: inherit;
  }

  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 300ms ease;
  }

  a:hover {
    color: ${({ theme }) => theme.color.terracotaDark};
    text-decoration: underline;
    text-underline-offset: ${({ theme }) => theme.spacing.xxs};
  }

  body {
    ${({ theme }) => theme.typo.p}
    color: ${({ theme }) => theme.color.black};
    background-color: white;
  }

  button {
    ${({ theme }) => theme.typo.p}
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    text-align: center;
  }

  input:focus,
  select:focus,
  textarea:focus {
    border-color: ${({ theme }) => theme.color.terracota};
    outline: none
  }

  .blur {
    filter: blur(5px);
  }

  .round {
    border-radius: 50%;
  }

  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }
`
