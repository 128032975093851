import { IGQLPageVariables, IPageGQLResults } from '@/services/api-graphql'
import { signInFromStorage, signInRefresh } from '@/services/auth'
import { Config } from '@/services/config'
import { isSSR } from '@/utils/misc'

export const apiVersion = Config.apiUrl.split('/').pop()

export interface FetcherArgs {
  uri: string
  baseUrl?: string
  params?: { [key: string]: any }
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'
  data?: { [key: string]: any }
  formData?: { [key: string]: any }
  authentication?: 'required' | 'optional' | 'none'
  customHeaders?: HeadersInit
  ignoreSignInRefreshOn401?: boolean
}

export const fetcher = async <T,>({
  uri,
  baseUrl,
  params,
  method,
  data,
  formData,
  authentication,
  customHeaders,
  ignoreSignInRefreshOn401 = false,
}: FetcherArgs): Promise<T> => {
  // Refresh auth if required before fetch
  if (!isSSR && ['optional', 'required'].includes(authentication)) {
    try {
      await signInFromStorage()
    } catch (err) {
      if (authentication === 'required') {
        throw err
      }
    }
  }

  const fetchPromise = fetch(
    `${baseUrl ?? Config.apiUrl}${uri}${serializeParams(params)}`,
    {
      method,
      credentials: isSSR || authentication === 'none' ? 'omit' : 'include',
      headers: customHeaders ?? {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...(data && { body: JSON.stringify(data || {}) }),
      ...(formData && { body: buildFormData(formData) }),
    }
  )

  let response = await fetchPromise

  // Special case of revoke JWT on server side
  if (!ignoreSignInRefreshOn401 && response.status === 401) {
    try {
      await signInRefresh()
      response = await fetchPromise
    } catch (err) {
      if (authentication === 'required') {
        throw err
      }
    }
  }

  if (response.status === 204) {
    return
  }

  const body = await response.json()
  if (!response.ok) {
    throw new HTTPError(response, body)
  }

  return body
}

export const fetchGetJSON = <T,>(args: FetcherArgs): Promise<T> =>
  fetcher({
    ...args,
    method: 'GET',
  })

export const fetchPostJSON = <T,>(args: FetcherArgs): Promise<T> =>
  fetcher({
    ...args,
    method: 'POST',
  })

export const fetchPutJSON = <T,>(args: FetcherArgs): Promise<T> =>
  fetcher({
    ...args,
    method: 'PUT',
  })

export const fetchPatchJSON = <T,>(args: FetcherArgs): Promise<T> =>
  fetcher({
    ...args,
    method: 'PATCH',
    customHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/merge-patch+json',
    },
  })

type IGqlResponse<T> = {
  data?: T
  errors?: { message: string; extensions: { category: string; status: number } }[]
}

export const fetchGraphQL = async <T,>(
  query: string,
  params: FetcherArgs['params'] = {}
): Promise<IGqlResponse<T>> => {
  const { authentication = 'optional', ...variables } = params
  const response = await fetchPostJSON<IGqlResponse<T>>({
    uri: '/api/graphql',
    data: { query, variables },
    authentication,
  })

  if (response.errors?.length) {
    throw new Error(JSON.stringify(response.errors))
  }

  return response
}

const serializeParams = (params?: FetcherArgs['params']): string => {
  if (!params) {
    return ''
  }

  const paramsStr = Object.entries(params).reduce((acc, [key, val]) => {
    if (typeof val === 'undefined') {
      return acc
    }

    // if (val === null) {
    //   acc.push(`${key}=null`)
    //   return acc
    // }

    if (Array.isArray(val)) {
      val.forEach((vv) => acc.push(`${key}[]=${vv}`))
      return acc
    }

    if (typeof val === 'object') {
      Object.entries(val).forEach(([k, v]) => acc.push(`${key}[${k}]=${v}`))
      return acc
    }

    acc.push(`${key}=${val}`)
    return acc
  }, [])

  return paramsStr.length ? `?${paramsStr.join('&')}` : ''
}

const buildFormData = (fData: FetcherArgs['formData']) => {
  const formData = new FormData()

  Object.entries(fData).forEach(([k, v]) => formData.append(k, v))

  return formData
}

export class HTTPError extends Error {
  status: number
  url: string
  body: unknown
  code: string

  constructor(
    response: Response,
    body: { detail?: string; violations?: { code: string }[] },
    customStatus?: number
  ) {
    super(body.detail ?? response.statusText)
    this.name = 'HTTPError'
    this.status = customStatus || response.status
    this.url = response.url
    this.body = body
    this.code = body.violations?.[0].code ?? body.detail ?? 'unknown'
  }
}

export async function* iterateGqlResults<
  T,
  U extends IGQLPageVariables = IGQLPageVariables,
  V extends IPageGQLResults<T[]> = IPageGQLResults<T[]>,
>(
  gqlFn: (gqlVariables: U) => Promise<V>,
  gqlVariables?: U
): AsyncGenerator<T, void, undefined> {
  let pageIndex = 1

  while (true) {
    const results = await gqlFn({ itemsPerPage: 100, pageIndex, ...gqlVariables })
    if (!results.collection?.length) {
      break
    }

    pageIndex++
    yield* results.collection
  }
}
