import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { NextLink } from '@/components/_shared/NextLink'
import { routes } from '@/utils/route'

export const Copyright: React.FC = () => (
  <Wrapper>
    <StyledContainer>
      <Text>© OLY Be {new Date().getFullYear()}.</Text>
      <StyledLink href={routes.cgv}>
        <FormattedMessage
          defaultMessage="Conditions générales de ventes"
          description="Footer > Copyright: CGV link"
        />
      </StyledLink>
      <StyledLink href={routes.privacy}>
        <FormattedMessage
          defaultMessage="Politique de confidentialité"
          description="Footer > Copyright: Privacy link"
        />
      </StyledLink>
      <StyledLink href={routes.cgu}>
        <FormattedMessage
          defaultMessage="Mentions légales"
          description="Footer > Copyright: Legals mentions link"
        />
      </StyledLink>
    </StyledContainer>
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.black};
`
const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: ${({ theme }) => theme.spacing.s};
  padding-top: ${({ theme }) => theme.spacing.s};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`
const Text = styled.p`
  ${({ theme }) => theme.typo.mention}

  color: ${({ theme }) => theme.color.white};
`
const StyledLink = styled(NextLink)`
  ${({ theme }) => theme.typo.mention}

  color: ${({ theme }) => theme.color.white};

  &:hover {
    color: ${({ theme }) => theme.color.greyLight};
  }
`
