import { jwtDecode } from 'jwt-decode'

import { IPasswordForgotFormValues } from '@/components/auth/PasswordForgotForm'
import { IPasswordUpdateFormValues } from '@/components/auth/PasswordUpdateForm'
import { ISignInFormValues } from '@/components/auth/SignInForm'
import { ISignUpFormValues } from '@/components/auth/SignUpForm'
import { IOnboarding } from '@/types/main'
import {
  readCookie,
  readSessionStorage,
  removeCookie,
  removeSessionStorage,
} from '@/utils/storage'

import {
  createUser,
  ILoginCheckResponse,
  invalidateToken,
  ISignUpResponse,
  IUpdatePasswordResponse,
  loginCheck,
  refreshTokens,
  resetPassword,
  udpatePassword,
  updateUserOnboarding,
} from './api-auth'
import { Config } from './config'

export const COOKIE_JWT_HP = `${Config.jwtCookiePrefix}jwt_hp`

export interface JWTPayload {
  exp: number
  uuid: string
  email: string
  firstName: string
  lastName: string
  subscriber: boolean
  skipTrial: boolean
  b2b: boolean
}

let pendingRefreshTokensPromise: Promise<ILoginCheckResponse> = null

export const signInFromForm = async (
  signInFormValues: ISignInFormValues
): Promise<JWTPayload> => {
  await loginCheck(signInFormValues)

  const payload = await signInFromStorage()

  const onboarding = readSessionStorage<IOnboarding>('onboarding')
  if (onboarding) {
    persistUserOnboarding(payload.uuid, onboarding)
  }

  return payload
}

const persistUserOnboarding = async (userUuid: string, onboarding: IOnboarding) => {
  try {
    await updateUserOnboarding(userUuid, onboarding)
    removeSessionStorage('onboarding')
  } catch (e) {
    console.log(e)
  }
}

export const signInFromStorage = async (): Promise<JWTPayload> => {
  try {
    return getValidJwtPayload()
  } catch (err) {
    if (['no_jwt_cookie', 'jwt_decode_failure'].includes(err.message)) {
      signOut()
      throw new Error('jwt_failure1')
    }
  }

  return signInRefresh()
}

export const signInRefresh = async (): Promise<JWTPayload> => {
  if (!pendingRefreshTokensPromise) {
    pendingRefreshTokensPromise = refreshTokens()
  }

  try {
    await pendingRefreshTokensPromise
  } catch {
    signOut({ withTokenInvalidation: true })
    throw new Error('refresh_token_failure1')
  } finally {
    pendingRefreshTokensPromise = null
  }

  try {
    return getValidJwtPayload()
  } catch {
    signOut()
    throw new Error('refresh_token_failure2')
  }
}

export const passwordForgot = (
  passwordForgotFormValues: IPasswordForgotFormValues
): Promise<void> => resetPassword(passwordForgotFormValues)

export const passwordUpdate = (
  passwordUpdateFormValues: IPasswordUpdateFormValues
): Promise<IUpdatePasswordResponse> => udpatePassword(passwordUpdateFormValues)

export const signUp = (signUpFormValues: ISignUpFormValues): Promise<ISignUpResponse> =>
  createUser(signUpFormValues)

export const signOut = async (
  { withTokenInvalidation } = { withTokenInvalidation: false }
): Promise<void> => {
  removeCookie(COOKIE_JWT_HP, { allowSubdomains: true })

  if (withTokenInvalidation) {
    try {
      await invalidateToken()
    } catch {
      // Silent catch
    }
  }
}

const getValidJwtPayload = (): JWTPayload => {
  const jwtPayload = readCookie(COOKIE_JWT_HP)
  if (!jwtPayload) {
    throw new Error('no_jwt_cookie')
  }

  const decodedJwtPayload = jwtDecode<JWTPayload>(jwtPayload)
  if (!decodedJwtPayload) {
    throw new Error('jwt_decode_failure')
  }

  if (1000 * decodedJwtPayload.exp - 5000 < Date.now()) {
    throw new Error('jwt_expired')
  }

  return decodedJwtPayload
}
