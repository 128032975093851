import type { Content } from '@prismicio/client'
import { useRouter } from 'next/router'
import React from 'react'
import { useIntl } from 'react-intl'

import { Container } from '@/components/_layout/Container'
import { Breadcrumb } from '@/components/_shared/Breadcrumb'
import {
  ILessonDecorated,
  IPlaceDecorated,
  IPropertyValueArea,
  IPropertyValueDiscipline,
  ISerieDecorated,
  ITeacherDecorated,
  IVideoDecorated,
  IVideoGroupDecorated,
} from '@/types/main'
import { BREADCRUMB_BYPATH } from '@/utils/breadcrumb'

export interface IAutoBreadcrumbProps {
  lesson?: ILessonDecorated
  serie?: ISerieDecorated
  place?: IPlaceDecorated
  video?: IVideoDecorated
  videoGroup?: IVideoGroupDecorated
  teacher?: ITeacherDecorated
  discipline?: IPropertyValueDiscipline
  area?: IPropertyValueArea
  prismicDocument?: Content.PageDocument | Content.BlogpostDocument
}

/**
 * !!! If used in page with custom props, set MainLayout.disableAutoBreadcrumb
 */
export const AutoBreadcrumb: React.FC<IAutoBreadcrumbProps> = (props) => {
  const router = useRouter()
  const intl = useIntl()

  const pagePath = router.asPath.split('?')[0]
  const byPathFn = BREADCRUMB_BYPATH[pagePath] ?? BREADCRUMB_BYPATH[router.pathname]

  if (!byPathFn) {
    return null
  }

  return (
    <Container>
      <Breadcrumb items={byPathFn(intl, props)} />
    </Container>
  )
}
