import { addHours, differenceInHours, isPast, isWithinInterval, subHours } from 'date-fns'

import { IPriceDecoratedGQLResults, IPriceGQLResults } from '@/services/api-graphql-price'
import {
  ILesson,
  ILessonDecorated,
  IPlace,
  IPlaceDecorated,
  IPrice,
  IPriceDecorated,
  IPropertyKey,
  IPropertyValue,
  ISerie,
  ISerieDecorated,
  ITeacher,
  ITeacherDecorated,
  IVideo,
  IVideoDecorated,
  IVideoGroup,
  IVideoGroupDecorated,
} from '@/types/main'

import { AREA_DUAL_KEY, BOOKING_FINALITY } from './constants'
import { capitalize } from './text'

export const decorateLesson = (
  lesson: ILesson,
  mutate?: ILessonDecorated['mutate']
): ILessonDecorated => ({
  ...lesson,
  mutate: mutate ?? unconfiguredMutate(lesson),
  isInProgress: () =>
    isWithinInterval(new Date(), {
      start: new Date(lesson.startAt),
      end: new Date(lesson.endAt),
    }),
  isFinished: () => isPast(new Date(lesson.endAt)),
  renderArea: () => capitalize(lesson.area),
  isOnline: () => lesson.area === AREA_DUAL_KEY.ONLINE,
  isB2B: () => lesson.type === 'b2b',
  getAuthUserActiveBookings: ({ guestsOnly } = {}) => {
    if (guestsOnly) {
      return (
        lesson.authUserVisits?.filter(
          (visit) => !visit.canceledAt && !!visit.guestEmail
        ) ?? []
      )
    }

    return lesson.authUserVisits?.filter((visit) => !visit.canceledAt) ?? []
  },
  hasAuthUserActiveBookings: () =>
    lesson.authUserVisits?.some((visit) => !visit.canceledAt) ?? false,
  hasAuthUserWaitlistBookings: ({ targetVisit } = {}) => {
    if (targetVisit) {
      return !targetVisit.canceledAt && targetVisit.waitlist
    }

    return (
      lesson.authUserVisits?.some((visit) => !visit.canceledAt && visit.waitlist) ?? false
    )
  },
  getAuthUserFinalityBookings: () => {
    const firstValidVisit = lesson.authUserVisits?.find(
      (visit) => !visit.canceledAt || visit.lateCanceled
    )

    if (!firstValidVisit) {
      return BOOKING_FINALITY.NONE
    }

    if (firstValidVisit.lateCanceled) {
      return BOOKING_FINALITY.LATE_CANCELED
    }

    if (lesson.area === AREA_DUAL_KEY.ONLINE) {
      return BOOKING_FINALITY.NONE
    }

    return firstValidVisit.signIn ? BOOKING_FINALITY.PRESENT : BOOKING_FINALITY.ABSENT
  },
  isAuthUserWillBeLateCancellation: ({ userAdvantages } = {}) => {
    const lateCancelDelay = userAdvantages?.late_cancel?.delay ?? 24

    return differenceInHours(new Date(lesson.startAt), new Date()) < lateCancelDelay
  },
  getAuthUserZoomWebinarParams: () => {
    const firstValidVisit = lesson.authUserVisits?.find(
      (visit) => !visit.canceledAt && !!visit.webinarZoomJoinUrl
    )

    if (!firstValidVisit) {
      return null
    }

    const url = new URL(firstValidVisit.webinarZoomJoinUrl)

    return {
      meetingNumber: +url.pathname.split('/').pop(),
      password: url.searchParams.get('pwd'),
      registrantToken: url.searchParams.get('tk'),
    }
  },
  isAuthUserZoomWebinarAllowedInterval: () =>
    isWithinInterval(new Date(), {
      start: subHours(new Date(lesson.startAt), 1),
      end: addHours(new Date(lesson.endAt), 2),
    }),
})

export const decorateSerie = (
  serie: ISerie,
  mutate?: ISerieDecorated['mutate']
): ISerieDecorated => ({
  ...serie,
  mutate: mutate ?? unconfiguredMutate(serie),
  isOnline: () => serie.area === AREA_DUAL_KEY.ONLINE,
  isB2B: () => serie.type === 'b2b',
})

export const decorateTeacher = (
  teacher: ITeacher,
  mutate?: ITeacherDecorated['mutate']
): ITeacherDecorated => ({
  ...teacher,
  mutate: mutate ?? unconfiguredMutate(teacher),
})

export const decoratePlace = (
  place: IPlace,
  mutate?: IPlaceDecorated['mutate']
): IPlaceDecorated => ({
  ...place,
  mutate: mutate ?? unconfiguredMutate(place),
  renderArea: () => capitalize(place.area),
})

export const decoratePrices = (
  priceResults: IPriceGQLResults
): IPriceDecoratedGQLResults => {
  const priceCollection = priceResults.collection
    .filter((price) => price.eligible)
    .map((price) =>
      decoratePrice({
        ...price,
      })
    )

  return {
    ...priceResults,
    collection: priceCollection,
  }
}

export const decoratePrice = (price: IPrice): IPriceDecorated => ({
  ...price,
  renderLabel: (withAltFallback = false) => {
    if (!price.subscription || price.allowTrial) {
      return price.websiteRender.label
    }

    return withAltFallback
      ? price.websiteRender.label_alt ?? price.websiteRender.label
      : price.websiteRender.label_alt
  },
  isOnlineOnly: () =>
    price.areas?.length === 1 && price.areas[0] === AREA_DUAL_KEY.ONLINE,
  isB2B: () => price.business === 'b2b',
})

export const decorateVideo = (
  video: IVideo,
  mutate?: IVideoDecorated['mutate']
): IVideoDecorated => ({
  ...video,
  mutate: mutate ?? unconfiguredMutate(video),
})

export const decorateVideoGroup = (
  videoGroup: IVideoGroup,
  mutate?: IVideoGroupDecorated['mutate']
): IVideoGroupDecorated => ({
  ...videoGroup,
  mutate: mutate ?? unconfiguredMutate(videoGroup),
})

const unconfiguredMutate =
  <T,>(misc: T) =>
  async () => {
    console.log('mutate unconfigured')
    return misc
  }

// JS alternative to the use of graphql children & parent fields that occurs DB complexity
export const enrichPropertyKeys = <T extends IPropertyValue>(
  propertyKeys: readonly IPropertyKey<T>[]
): IPropertyKey<T>[] =>
  propertyKeys.map((propKey) => ({
    ...propKey,
    propValues: enrichPropertyValues<T>(propKey.propValues),
  }))

export const enrichPropertyValues = <T extends IPropertyValue>(
  propertyValues: T[]
): T[] => {
  if (!propertyValues.length) {
    return []
  }

  const propValueMap = propertyValues.reduce(
    (map, propValue) => map.set(propValue._id, propValue),
    new Map<number, T>()
  )

  const buildChildRecur = (child: T, parent: T = null) => ({
    ...child,
    children:
      child.childrenIdentifiers
        ?.map((id) => buildChildRecur(propValueMap.get(id), { ...child, parent }))
        .sort((a, b) => b.weight - a.weight) ?? [],
    parent,
  })

  return propertyValues
    .filter((propValue) => !propValue.parentIdentifier)
    .reduce(
      (acc, propValue) => [
        ...acc,
        buildChildRecur(propValue, propValueMap.get(propValue.parentIdentifier)),
      ],
      []
    )
}

export const flattenPropertyValues = <T extends IPropertyValue>(
  propertyValues: T[]
): T[] =>
  propertyValues.reduce(
    (acc, propValue) => [
      ...acc,
      propValue,
      ...propValue.children.flatMap((propValueChild1) => [
        propValueChild1,
        ...propValueChild1.children.map((propValueChild2) => propValueChild2),
      ]),
    ],
    []
  )
