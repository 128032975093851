import styled, { css } from 'styled-components'
import { theme } from 'theme'

import { NextLink } from '@/components/_shared/NextLink'
import { DEVICE_WIDTH } from '@/utils/constants'

/**
 * StyleComponents - Common
 */

export const CenteredWrapper = styled.div`
  text-align: center;
`
export const CenteredImageWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.s} auto;
  text-align: center;
`

/**
 * StyleComponents - RichText
 */

const stylePrismicFlags = css`
  & > span.center-align {
    display: block;
    text-align: center;
  }
  & > span.right-align {
    display: block;
    text-align: right;
  }
`

// Headings

export const Heading1 = styled.h1<{ $center: boolean; $withSubTitle: boolean }>`
  ${({ theme }) => theme.typo.h1}
  ${stylePrismicFlags}

  ${({ $center = false }) =>
    $center &&
    css`
      text-align: center;
    `}

  margin-bottom: ${({ theme, $withSubTitle = false }) =>
    $withSubTitle ? theme.spacing.s : theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme, $withSubTitle = false }) =>
      $withSubTitle ? theme.spacing.xs : theme.spacing.ms};
  }
`
export const Heading2 = styled.h2<{ $center?: boolean }>`
  ${({ theme }) => theme.typo.h2}
  ${stylePrismicFlags}

  ${({ $center = false }) =>
    $center &&
    css`
      text-align: center;
    `}

  margin-bottom: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.ms};
  }
`
export const Heading3 = styled.h3`
  ${({ theme }) => theme.typo.h3}
  ${stylePrismicFlags}

  margin-bottom: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }
`
export const Heading4 = styled.h4`
  ${({ theme }) => theme.typo.h4}
  ${stylePrismicFlags}

  margin-bottom: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`
export const Heading5 = styled.h5`
  ${({ theme }) => theme.typo.h5}
  ${stylePrismicFlags}

  margin-bottom: ${({ theme }) => theme.spacing.xs};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }
`

// Misc

export const Bold = styled.span`
  ${({ theme }) => theme.typo.bold}
`
export const Paragraph = styled.p`
  ${stylePrismicFlags}
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  &:empty {
    height: 22px;
  }
`

// List

export const List = styled.ul`
  margin: ${({ theme }) => theme.spacing.xs} 0;
  padding-left: ${({ theme }) => theme.spacing.m};
`
export const ListItem = styled.li`
  &::before {
    color: ${({ theme }) => theme.color.terracota};
    content: '•';
    display: inline-block;
    margin-left: -1em;
    width: 1em;
  }
`
export const OList = styled.ol`
  margin: ${({ theme }) => theme.spacing.xs} 0;
  padding-left: ${({ theme }) => theme.spacing.l};
`
export const OListItem = styled.li`
  &::marker {
    color: ${({ theme }) => theme.color.terracota};
  }
`

// Links

export const Link = styled(NextLink)`
  color: ${({ theme }) => theme.color.terracota};

  ${({ theme }) => theme.typo.link}
`

// Embed

export const Embed = styled.div`
  aspect-ratio: 7/4;
  // border-radius: ${({ theme }) => theme.spacing.ms};
  max-width: 100%;
  overflow: hidden;
  position: relative;

  @supports not (aspect-ratio: 1) {
    padding-top: 57.14%;
  }

  > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

/**
 * Fn
 */

export type IStyleMarginTop = 'default' | 'none' | 'simple' | 'double'

export const computeMarginTop = (
  marginTop: IStyleMarginTop = 'default',
  targetMobile = false
) => {
  const marginTopMap = new Map<IStyleMarginTop, string | 0>([
    ['none', 0],
    ['simple', targetMobile ? theme.spacing.ms : theme.spacing.m],
    ['double', targetMobile ? theme.spacing.xs : theme.spacing.xxl],
  ])

  return marginTopMap.get(marginTop) ?? marginTopMap.get('double')
}
