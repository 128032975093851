import type { Content } from '@prismicio/client'
import { useRouter } from 'next/router'
import React from 'react'
import { useIntl } from 'react-intl'

import { CustomNextSeo } from '@/components/_shared/CustomNextSeo'
import { Config } from '@/services/config'
import {
  ILessonDecorated,
  IPlaceDecorated,
  IPropertyValueArea,
  IPropertyValueDiscipline,
  ISerieDecorated,
  ITeacherDecorated,
  IVideoDecorated,
  IVideoGroupDecorated,
} from '@/types/main'
import { SEO_BYPATH } from '@/utils/seo'

export interface IAutoSeoProps {
  lesson?: ILessonDecorated
  serie?: ISerieDecorated
  place?: IPlaceDecorated
  video?: IVideoDecorated
  videoGroup?: IVideoGroupDecorated
  teacher?: ITeacherDecorated
  discipline?: IPropertyValueDiscipline
  area?: IPropertyValueArea
  prismicDocument?: Content.PageDocument | Content.BlogpostDocument
}

/**
 * !!! If used in page with custom props, set MainLayout.disableAutoSeo
 */
export const AutoSeo: React.FC<IAutoSeoProps> = (props) => {
  const router = useRouter()
  const intl = useIntl()

  const pagePath = router.asPath.split('?')[0]
  const byPathFn = SEO_BYPATH[pagePath] ?? SEO_BYPATH[router.pathname]

  if (!byPathFn || (router.pathname === '/[prismicUid]' && !props.prismicDocument)) {
    return null
  }

  const seoProps = byPathFn(intl, props)
  const canonicalUri = seoProps.noindex ? null : seoProps.canonicalUri ?? pagePath

  return (
    <CustomNextSeo
      {...seoProps}
      canonicalUri={canonicalUri && `${Config.websiteUrl}${canonicalUri}`}
    />
  )
}
