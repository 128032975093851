import Link, { LinkProps } from 'next/link'
import React, { ComponentPropsWithoutRef } from 'react'

export type INextLinkProps = LinkProps & Omit<ComponentPropsWithoutRef<'a'>, 'href'>

export const NextLink: React.FC<React.PropsWithChildren<INextLinkProps>> = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  children,
  ...props
}) => (
  <Link
    passHref={true}
    href={href}
    as={as}
    replace={replace}
    scroll={scroll}
    shallow={shallow}
    prefetch={prefetch}
    {...props}
  >
    {children}
  </Link>
)
