import useSWR, { Fetcher, SWRConfiguration, SWRResponse } from 'swr'
import useSWRMutation, {
  SWRMutationConfiguration,
  SWRMutationResponse,
} from 'swr/mutation'

import { HTTPError } from '@/utils/api-helpers'

export const useSWRCustom = <Data,>(
  fetcherKey: string[],
  fetcher?: Fetcher<Data>,
  fetcherArgs: any = {},
  swrConfig: SWRConfiguration<Data, HTTPError> = {}
): SWRResponse<Data, HTTPError> => {
  const [mainKey, authUserUuid = 'unauth'] = fetcherKey

  return useSWR(
    mainKey && [`api_${mainKey}`, fetcherArgs, authUserUuid],
    () => (mainKey ? fetcher(fetcherArgs) : undefined),
    swrConfig
  )
}

export const useSWRMutateCustom = <Data,>(
  fetcherKey: string[],
  fetcher?: Fetcher<Data>,
  fetcherArgs: any = {},
  swrConfig: SWRMutationConfiguration<Data, HTTPError> = {}
): SWRMutationResponse<Data, HTTPError> => {
  const [mainKey, authUserUuid = 'unauth'] = fetcherKey

  return useSWRMutation(
    mainKey && [`api_${mainKey}`, fetcherArgs, authUserUuid],
    () => (mainKey ? fetcher(fetcherArgs) : undefined),
    swrConfig
  )
}
