import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const FacebookIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="12"
    height="23"
    viewBox="0 0 12 23"
    fill={theme.color.white}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11.8435 0.160286V3.696H9.74089C8.09346 3.696 7.78575 4.48629 7.78575 5.62457V8.15571H11.7097L11.1877 12.12H7.78575V22.2849H3.68775V12.12H0.272888V8.15571H3.68775V5.23629C3.68775 1.848 5.76375 0 8.79032 0C10.2363 0 11.4826 0.107143 11.8443 0.160286H11.8435Z" />
  </svg>
)
