import type { Content } from '@prismicio/client'
import React, { ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { theme } from 'theme'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { useSWRPriceResult } from '@/hooks/swr/useSwr-priceResult'
import { DEVICE_WIDTH } from '@/utils/constants'
import { routes } from '@/utils/route'

interface IProps {
  cta: Content.MenuEntryListSliceDefault['primary']['cta']
}

export const MenuCta: React.FC<IProps> = ({ cta }) => {
  const intl = useIntl()

  if (cta === null) {
    return null
  }

  if (cta === 'elleArticle') {
    return (
      <GenericMenuCta
        subtitle={intl.formatMessage({
          defaultMessage: 'Abonnée ELLE',
          description: 'ElleArticleMenuCta: olybe subtitle',
        })}
        title={intl.formatMessage({
          defaultMessage: '6 semaines offertes',
          description: 'ElleArticleMenuCta: olybe title',
        })}
        colorBg={theme.color.beigeLight}
        buttonRenderer={
          <StyledButtonLink
            color={theme.color.black}
            href="/auth/signin?redirectTo=/abonnees-elle"
          >
            <FormattedMessage
              defaultMessage="J'en profite"
              description="ElleArticleMenuCta: buttonLabel"
            />
          </StyledButtonLink>
        }
      />
    )
  }

  if (cta === 'specialOffer') {
    return <SpecialOfferMenuCta />
  }

  if (cta === 'guidance') {
    return (
      <GenericMenuCta
        subtitle={intl.formatMessage({
          defaultMessage: 'Découvrir',
          description: 'GuidanceMenuCta: olybe subtitle',
        })}
        title={intl.formatMessage({
          defaultMessage: 'Quel cours est fait pour moi ?',
          description: 'GuidanceMenuCta: olybe title',
        })}
        colorBg={theme.color.beigeLight}
        buttonRenderer={
          <OnboardingButtonLink href={routes.onboarding} outlined>
            <FormattedMessage
              defaultMessage="Guidez-moi"
              description="GuidanceMenuCta: button"
            />
          </OnboardingButtonLink>
        }
      />
    )
  }
}

const OnboardingButtonLink = styled(CustomButtonLink)`
  background: ${({ theme }) => theme.color.terracota};
  color: ${({ theme }) => theme.color.white};
  margin: auto;
  width: fit-content;
`

const SpecialOfferMenuCta: React.FC = () => {
  const intl = useIntl()
  const { priceLoading, price, priceError } = useSWRPriceResult({
    priceUuid: 'e9cd7b9b-ba21-4bd7-aa16-df24580f4730',
  })

  if (priceLoading || priceError || !price?.eligible) {
    return (
      <GenericMenuCta
        subtitle={intl.formatMessage({
          defaultMessage: 'Cours offert',
          description: 'SpecialOfferMenuCta > authenticated: subtitle',
        })}
        title={intl.formatMessage(
          {
            defaultMessage: 'Parrainez vos <inlineBlock>ami·e·s !</inlineBlock>',
            description: 'SpecialOfferMenuCta > authenticated: title',
          },
          {
            inlineBlock: (chunks) => <InlineBlock>{chunks}</InlineBlock>,
          }
        )}
        buttonRenderer={
          <StyledButtonLink href={routes.account.referrals}>
            <FormattedMessage
              defaultMessage="Je parraine !"
              description="SpecialOfferMenuCta > authenticated: buttonLabel"
            />
          </StyledButtonLink>
        }
        colorBg={theme.color.pink}
      />
    )
  }

  return (
    <GenericMenuCta
      subtitle={intl.formatMessage({
        defaultMessage: 'Pour démarrer',
        description: 'SpecialOfferMenuCta: subtitle',
      })}
      title={intl.formatMessage({
        defaultMessage: "Profitez de l'offre découverte",
        description: 'SpecialOfferMenuCta: title',
      })}
      colorBg={theme.color.pink}
      buttonRenderer={
        <StyledButtonLink href={routes.pricing.studio}>
          <FormattedMessage
            defaultMessage="J'en profite !"
            description="SpecialOfferMenuCta: buttonLabel"
          />
        </StyledButtonLink>
      }
    />
  )
}

const InlineBlock = styled.span`
  display: inline-block;
`
const StyledButtonLink = styled(CustomButtonLink)`
  margin: auto;
  width: fit-content;
`

interface IGenericMenuCtaProps {
  subtitle: string
  title: string | React.ReactNode
  colorBg: string
  buttonRenderer: ReactNode
}

const GenericMenuCta: React.FC<IGenericMenuCtaProps> = ({
  subtitle,
  title,
  colorBg,
  buttonRenderer,
}) => {
  return (
    <Wrapper $colorBg={colorBg}>
      <Subtitle $color={getColor(colorBg)}>{subtitle}</Subtitle>
      <Title $color={getColor(colorBg)}>{title}</Title>
      {buttonRenderer}
    </Wrapper>
  )
}

const getColor = (colorBg: string) => {
  if (colorBg === theme.color.aqua) {
    return theme.color.white
  }

  return null
}

const Wrapper = styled.div<{ $colorBg: string }>`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    background-color: ${({ $colorBg }) => $colorBg};
    border-radius: 10000px;
    display: grid;
    gap: ${({ theme }) => theme.spacing.s};
    justify-content: space-between;
    margin: ${({ theme }) => `${theme.spacing.ms} ${theme.spacing.l}`};
    max-width: 280px;
    padding: ${({ theme }) => `${theme.spacing.l} ${theme.spacing.m}`};
    text-align: center;
  }
`
const Subtitle = styled.p<{ $color: string }>`
  color: ${({ theme, $color }) => $color ?? theme.color.greyDarker};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`
const Title = styled.div<{ $color: string }>`
  ${({ theme }) => theme.typo.h2};

  text-align: center;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`
