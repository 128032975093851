export const Config = {
  websiteUrl: process.env.NEXT_PUBLIC_WEBSITE_URL,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  imgixDomain: process.env.NEXT_PUBLIC_IMGIX_DOMAIN,
  imgixSecureUrlToken: process.env.NEXT_PUBLIC_IMGIX_SECURE_URL_TOKEN,
  stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || 'pk_XXX',
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-XXX',
  googleApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  googleMapsStyleId: process.env.NEXT_PUBLIC_GOOGLE_MAPS_STYLE_ID,
  prismicApiKey: process.env.NEXT_PUBLIC_PRISMIC_API_KEY,
  zoomSdkKey: process.env.NEXT_PUBLIC_ZOOM_SDK_KEY,
  zoomSdkSecret: process.env.ZOOM_SDK_SECRET,
  routePlaylistFree:
    process.env.NEXT_PUBLIC_ROUTE_PLAYLIST_FREE || '/playlists/yoga-for-all-acces-libre',
  elleIntegrationEnabled: process.env.NEXT_PUBLIC_ELLE_INTEGRATION_ENABLED === '1',
  jwtCookiePrefix: process.env.NEXT_PUBLIC_JWT_COOKIE_PREFIX || 'olybe_',
}
