export enum DEVICE_WIDTH {
  MOBILE = 768,
  TABLET = 1024,
  DESKTOP = 1440,
}

export enum TIMELINE {
  PAST = 'past',
  FUTURE = 'future',
}

export enum BOOKING_FINALITY {
  NONE = 'none',
  LATE_CANCELED = 'lateCanceled',
  PRESENT = 'present',
  ABSENT = 'absent',
}

export enum AREA_DUAL_KEY {
  ONSITE = 'onsite',
  ONLINE = 'online',
}

export const AREA_DUAL_MAP = {
  [AREA_DUAL_KEY.ONSITE]: 'studio',
  [AREA_DUAL_KEY.ONLINE]: 'online',
}

export enum TRIO_KEY {
  B2B = 'b2b',
  B2C_ONSITE = 'b2c_onsite',
  B2C_ONLINE = 'b2c_online',
}

export enum ACTIVITY {
  STUDIO = 'studio',
  LIVE = 'live',
  REPLAY = 'replay',
  VIDEO = 'video',
}

export const FROM_PRICES = {
  studio: 8,
}

export const OPTINS_CONSENT_KEYS = {
  nl: ['email.newsletter'],
  misc: ['email.promotion_lesson', 'email.promotion_price'],
  elle: ['shareData.elle'],
}
