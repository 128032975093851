import { SWRConfiguration } from 'swr'

import { getPriceResult, IPriceResultVariables } from '@/services/api-graphql-price'
import { IPrice, IPriceDecorated } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'
import { decoratePrice } from '@/utils/decorator'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__PRICE_RESULT = 'price_result'

interface IReturnType {
  priceLoading: boolean
  price: IPriceDecorated
  priceError: HTTPError
}

export const useSWRPriceResult = (
  gqlVariables: IPriceResultVariables,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { authenticated, payload } = useSWRAuth()
  const { data, error, isLoading } = useSWRCustom<IPrice>(
    [authenticated && gqlVariables.priceUuid && SWR_KEY__PRICE_RESULT, payload?.uuid],
    getPriceResult,
    gqlVariables,
    swrConfig
  )

  return {
    priceLoading: isLoading,
    price: data && decoratePrice(data),
    priceError: error,
  }
}
