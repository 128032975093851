import React from 'react'
import styled from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'
import { IBreadCrumbItem } from '@/utils/breadcrumb'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  items: IBreadCrumbItem[]
}

export const Breadcrumb: React.FC<IProps> = ({ items }) => (
  <BreadcrumbNav
    aria-label="breadcrumb"
    vocab="https://schema.org/"
    typeof="BreadcrumbList"
  >
    {items.map(({ url, label }, index) => (
      <ListItem key={index} property="itemListElement" typeof="ListItem">
        {url ? (
          <Link href={url} property="item" typeof="WebPage">
            <Label property="name">{label}</Label>
          </Link>
        ) : (
          <Label property="name">{label}</Label>
        )}
        <meta property="position" content={index + 1 + ''} />
      </ListItem>
    ))}
  </BreadcrumbNav>
)

const BreadcrumbNav = styled.ol`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    display: flex;
    list-style: none;
    margin-top: ${({ theme }) => theme.spacing.s};
  }
`
const ListItem = styled.li`
  ${({ theme }) => theme.typo.mention}

  color: ${({ theme }) => theme.color.grey};

  &:not(:last-child)::after {
    content: '/';
    display: inline-block;
    margin: 0 ${({ theme }) => theme.spacing.xs};
  }
`
const Link = styled(NextLink)`
  ${({ theme }) => theme.typo.bold}

  color: ${({ theme }) => theme.color.terracota};
`
const Label = styled.span``
