import useSWR, { KeyedMutator } from 'swr'

import { authFetcher } from '@/services/api-auth'
import { JWTPayload } from '@/services/auth'
import { isSSR } from '@/utils/misc'

interface IReturnType {
  authLoading: boolean
  loggedOut: boolean
  payload: JWTPayload
  mutatePayload: KeyedMutator<JWTPayload>
  authenticated: boolean
}

export const useSWRAuth = (): IReturnType => {
  const { data, error, mutate, isLoading } = useSWR('local_user', authFetcher)

  return {
    authLoading: isLoading,
    loggedOut: error?.status === 401,
    payload: data,
    mutatePayload: mutate,
    authenticated: !!data?.uuid,
  }
}
