import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const UserIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="34"
    height="50"
    viewBox="0 0 34 50"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M31.1972 49.7502C30.6005 49.7502 30.0282 49.5131 29.6063 49.0911C29.1843 48.6692 28.9472 48.0969 28.9472 47.5002V41.1552C28.9472 37.9865 27.6885 34.9476 25.4479 32.707C23.2073 30.4664 20.1684 29.2077 16.9997 29.2077C13.8311 29.2077 10.7922 30.4664 8.55159 32.707C6.311 34.9476 5.05225 37.9865 5.05225 41.1552V47.5002C5.05225 48.0969 4.8152 48.6692 4.39324 49.0911C3.97128 49.5131 3.39898 49.7502 2.80225 49.7502C2.20551 49.7502 1.63321 49.5131 1.21126 49.0911C0.789299 48.6692 0.552246 48.0969 0.552246 47.5002V41.1552C0.552246 36.793 2.2851 32.6095 5.36961 29.525C8.45411 26.4405 12.6376 24.7076 16.9997 24.7076C21.3619 24.7076 25.5454 26.4405 28.6299 29.525C31.7144 32.6095 33.4472 36.793 33.4472 41.1552V47.5002C33.4472 48.0969 33.2102 48.6692 32.7882 49.0911C32.3663 49.5131 31.794 49.7502 31.1972 49.7502Z" />
    <path d="M17 23.155C14.7349 23.155 12.5207 22.4833 10.6374 21.2249C8.754 19.9665 7.2861 18.1779 6.41929 16.0852C5.55248 13.9925 5.32568 11.6898 5.76758 9.46823C6.20947 7.24667 7.30022 5.20603 8.90188 3.60436C10.5035 2.0027 12.5442 0.911959 14.7657 0.470062C16.9873 0.0281647 19.29 0.254962 21.3827 1.12177C23.4754 1.98859 25.264 3.45648 26.5224 5.33984C27.7808 7.22319 28.4525 9.43742 28.4525 11.7025C28.4466 14.7381 27.2381 17.6476 25.0916 19.7941C22.9451 21.9405 20.0356 23.1491 17 23.155ZM17 4.75C15.6249 4.75 14.2808 5.15776 13.1374 5.92171C11.9941 6.68566 11.103 7.77149 10.5767 9.0419C10.0505 10.3123 9.91285 11.7102 10.1811 13.0589C10.4494 14.4075 11.1115 15.6463 12.0839 16.6187C13.0562 17.591 14.295 18.2532 15.6437 18.5214C16.9923 18.7897 18.3902 18.652 19.6606 18.1258C20.931 17.5996 22.0169 16.7084 22.7808 15.5651C23.5448 14.4218 23.9525 13.0776 23.9525 11.7025C23.9466 9.86041 23.2122 8.09546 21.9096 6.7929C20.6071 5.49034 18.8421 4.75594 17 4.75Z" />
  </svg>
)
