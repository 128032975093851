import ImgixClient from '@imgix/js-core'
import { ImgixURLParams } from 'imgix-url-builder'
import { ImageLoaderProps } from 'next/image'

import { Config } from '@/services/config'

export const imgixSecureClient = new ImgixClient({
  domain: Config.imgixDomain,
  secureURLToken: Config.imgixSecureUrlToken,
  includeLibraryParam: false,
})

export const imgUrl = (
  imagePath: string,
  imgixOptions: ImgixURLParams = {}
): string | null => {
  if (!imagePath) {
    return null
  }

  return imgixSecureClient.buildURL(imagePath, {
    auto: ['compress', 'format'],
    ...imgixOptions,
  })
}

export const imgixLoader = (
  loaderProps: ImageLoaderProps,
  imgixParams?: ImgixURLParams
): string => {
  const adjustedImgixParams: ImgixURLParams = {
    ...imgixParams,
    q: loaderProps.quality,
    w: loaderProps.width,
    fit: imgixParams.fit ?? 'max',
  }

  return imgixSecureClient.buildURL(loaderProps.src, adjustedImgixParams)
}
