import { css, DefaultTheme } from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'

declare module 'styled-components' {
  export interface DefaultTheme {
    name: string
    color: typeof color
    spacing: typeof spacing
    typo: typeof typo
    tool: typeof tool
    zIndex: typeof zIndex
    boxShadow: typeof boxShadow
  }
}

const color = {
  terracotaLight: '#FFCFBB',
  terracota: '#F3966E',
  terracota50: 'rgba(243, 150, 110, 0.5)',
  terracotaDark: '#F2713A',
  terracotaDark50: 'rgba(242, 113, 58, 0.5)',
  mandarineLight: '#FFD89A',
  mandarine: '#F8AA2D',
  mandarineDark: '#F48B0F',
  beigeLight: '#FFF9F3',
  beige: '#FFF0D8',
  pinkLighter: '#FFEAE5',
  pinkLight: '#FFDDD6',
  pink: '#FEB9AB',
  pinkDark: '#FE7777',
  aqua: '#009AA9',
  aquaLighter: '#009AA933',
  aquaLight: '#009AA922',
  black: '#121212',
  black50: 'rgba(18, 18, 18, 0.2)',
  black80: 'rgba(18, 18, 18, 0.5)',
  white: '#ffffff',
  greyLighter: '#EEEEEE',
  greyLight: '#CECECE',
  grey: '#767676',
  greyDark: '#5E5D5D',
  greyDarker: '#4d4d4d',
  chakra: {
    1: '#ff5969',
    2: '#ff7c47',
    3: '#ffd345',
    4: '#5fa18e',
    5: '#5ca0d2',
    6: '#968ac0',
    7: '#b34bcc',
  },
}

const spacing = {
  xxs: '4px', // 8 / 2
  xs: '8px', // 8
  s: '16px', // 8 * 2
  ms: '24px', // 8 * 3
  m: '32px', // 8 * 4
  ml: '40px', // 8 * 5
  l: '48px', // 8 * 6
  xl: '56px', // 8 * 7
  xxl: '64px', // 8 * 8
  xxxl: '72px', // 8 * 9
}

const typo = {
  h1Home: css`
    font-family: 'Playfair Display Medium', serif;
    font-size: 72px;
    line-height: 66px;

    @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
      font-size: 72px;
      line-height: 90px;
    }
  `,
  h1: css`
    font-family: 'Playfair Display Medium', serif;
    font-size: 32px;
    line-height: 40px;

    @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
      font-size: 54px;
      line-height: 68px;
    }
  `,
  h2: css`
    font-family: 'Playfair Display Medium', serif;
    font-size: 28px;
    line-height: 34px;

    @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
      font-size: 40px;
      line-height: 54px;
    }
  `,
  h3: css`
    font-family: 'Inter Regular', sans-serif;
    font-size: 24px;
    line-height: 32px;

    @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
      font-size: 28px;
      line-height: 30px;
    }
  `,
  h4: css`
    font-family: 'Inter Regular', sans-serif;
    font-size: 20px;
    line-height: 28px;
  `,
  h5: css`
    font-family: 'Inter Regular', sans-serif;
    font-size: 16px;
    line-height: 24px;
  `,
  p: css`
    font-family: 'Inter Regular', sans-serif;
    font-size: 14px;
    line-height: 22px;
  `,
  mention: css`
    font-family: 'Inter Regular', sans-serif;
    font-size: 13px;
    line-height: 18px;
  `,
  tagFilters: css`
    font-family: 'Inter Semi-bold', sans-serif;
    font-size: 11px;
    line-height: 14px;
  `,
  overline: css`
    font-family: 'Inter Semi-bold', sans-serif;
    font-size: 13px;
    letter-spacing: 0.1em;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
  `,
  link: css`
    &:hover {
      color: ${({ theme }) => theme.color.terracotaDark};
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  `,
  bold: css`
    font-family: 'Inter Semi-bold', sans-serif;
  `,
  pointBefore: css`
    align-items: flex-start;
    display: flex;

    &::before {
      aspect-ratio: 1;
      background-color: ${({ theme }) => theme.color.pink};
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 12px;
      margin-right: 12px;
      margin-top: 10px;
      width: 12px;

      @supports not (aspect-ratio: 1) {
        min-height: 12px;
        min-width: 12px;
      }
    }

    @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
      &::before {
        margin-right: 18px;
      }
    }
  `,
}

const tool = {
  stretchedLink: css`
    &::after {
      content: '';
      inset: 0;
      position: absolute;
      z-index: 1;
    }
  `,
}

const zIndex = {
  dropdownMobile: 100,
  cookieBanner: 150,
  header: 200,
  filters: 300,
  dropdownTablet: 400,
  headerDesktop: 500,
  popin: 900,
}

const boxShadow = {
  neutral: '0 0px 12px 6px rgba(0, 0, 0, 0.03)',
  olybe: `0 0px 12px 6px ${color.terracota}`,
}

export const theme: DefaultTheme = {
  name: 'ob',
  color,
  spacing,
  typo,
  tool,
  zIndex,
  boxShadow,
}
