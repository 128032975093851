import { LinkProps } from 'next/link'
import { OpenGraph } from 'next-seo/lib/types'
import { IntlShape } from 'react-intl'
import { UrlObject } from 'url'

import { IAutoSeoProps } from '@/components/_layout/AutoSeo'
import { Config } from '@/services/config'
import { imgUrl } from '@/utils/image'
import { buildSerieExploreRoute, convertUrlObjectToString, routes } from '@/utils/route'

interface ISeoDetails {
  title: string
  description?: string
  noindex?: boolean
  canonicalUri?: LinkProps['href'] // URI beginning with '/', will be concatenated with project's url to build an absolute url
  openGraph?: OpenGraph
}
type ISeoDetailsList = {
  [routePath: string]: (intl: IntlShape, args: IAutoSeoProps) => ISeoDetails
}

const routesAuth: ISeoDetailsList = {
  [routes.auth.passwordForgot]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mot de passe oublié - OLY Be',
      description: 'Auth - PasswordForgot: title',
    }),
  }),
  [routes.auth.passwordUpdate]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Nouveau mot de passe - OLY Be',
      description: 'Auth - PasswordUpdate: title',
    }),
  }),
  [routes.auth.signin]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Se connecter - OLY Be',
      description: 'Auth - Signin: title',
    }),
  }),
  [routes.auth.signup]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Créer un compte - OLY Be',
      description: 'Auth - Signup: title',
    }),
  }),
}

const routesAtWork: ISeoDetailsList = {
  [routes.atwork.index]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Cours de sport et bien-être en entreprise - OLY Be',
      description: 'AtWork - MyOrg: title',
    }),
    description: intl.formatMessage({
      defaultMessage:
        'OLY Be propose des cours de sport, yoga, bien-être, développement personnel et coaching en entreprise.',
      description: 'AtWork - MyOrg: description',
    }),
  }),
  [routes.atwork.myorg]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Bien-être physique et mental en entreprise - OLY Be',
      description: 'AtWork - MyOrg: title',
    }),
    description: intl.formatMessage({
      defaultMessage:
        'Retrouvez votre planning des cours et ateliers OLY Be en entreprise : Sport, Bien-être et Santé mentale.',
      description: 'AtWork - MyOrg: description',
    }),
  }),
}

const routesBlog: ISeoDetailsList = {
  [routes.blog]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Notre blog',
      description: 'Blog: title',
    }),
  }),
  ['/blog/[prismicUid]']: (intl, { prismicDocument: { data: prismicDocumentData } }) => ({
    noindex: !prismicDocumentData.sitemap,
    title: prismicDocumentData.metaTitle ?? prismicDocumentData.title,
    description: prismicDocumentData.metaDescription,
    ...(prismicDocumentData.sitemap && {
      canonicalUri: prismicDocumentData.canonicalUri,
    }),
  }),
}

const routesBooking: ISeoDetailsList = {
  [routes.booking]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Votre réservation',
      description: 'Booking: title',
    }),
  }),
  [routes.bookingInvite]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Votre invitation',
      description: 'BookingInvite: title',
    }),
  }),
}

const routesDashboard: ISeoDetailsList = {
  [routes.dashboard]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Tableau de bord - OLY Be',
      description: 'Dashboard: title',
    }),
  }),
}

const routesExplore: ISeoDetailsList = {
  [routes.explore.discipline]: (intl, { discipline }) => ({
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours de {disciplineTitle} en studio et en ligne - OLY Be',
        description: 'Explore - Discipline: title',
      },
      { disciplineTitle: discipline.title }
    ),
    description: discipline.misc.description,
  }),
  [routes.explore.disciplines]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Cours de yoga, fitness et bien-être par disciplines - OLY Be',
      description: 'Explore - Disciplines: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Yoga tonique ou doux, renforcement, méditation... Découvrez tous nos cours de yoga, fitness et
        bien-être répartis selon leurs disciplines associées.
      `,
      description: 'Explore - Disciplines: description',
    }),
  }),
  [routes.explore.instructor]: (intl, { teacher }) => ({
    title: intl.formatMessage(
      {
        defaultMessage: 'Les cours de {teacherName} - OLY Be',
        description: 'Explore - Instructor: title',
      },
      { teacherName: teacher.fullName }
    ),
    description: intl.formatMessage(
      {
        defaultMessage:
          'Retrouvez tous nos cours en studio, en vidéo et en live avec {teacherName}.',
        description: 'Explore - Instructor: description',
      },
      { teacherName: teacher.fullName }
    ),
  }),
  [routes.explore.instructors]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Nos professeurs et experts testés et certifiés - OLY Be',
      description: 'Explore - Instructors: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Rencontrez nos professeurs et experts certifiés, tous testés et sélectionnés par l'équipe OLY Be
        pour vous offrir la meilleure expérience.
      `,
      description: 'Explore - Instructors: description',
    }),
  }),
  [routes.explore.live]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Cours en ligne, live & replay - OLY Be',
      description: 'Explore - Live: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Découvrez nos cours de Yoga, fitness et bien-être en studio près de chez vous à Paris - Lyon - Lille -
        Bordeaux - Nantes - Annecy - Rennes - Toulouse
      `,
      description: 'Explore - Live: description',
    }),
  }),
  [routes.explore.replay]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Cours en ligne, live & replay - OLY Be',
      description: 'Explore - Replay: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Découvrez nos cours de Yoga, fitness et bien-être en studio près de chez vous à Paris - Lyon - Lille -
        Bordeaux - Nantes - Annecy - Rennes - Toulouse
      `,
      description: 'Explore - Replay: description',
    }),
  }),
  [routes.explore.place]: (intl, { place }) => ({
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours OLY Be au studio {placeTitle}',
        description: 'Explore - Place: title',
      },
      {
        placeTitle: place.title,
      }
    ),
    description: intl.formatMessage(
      {
        defaultMessage: `
          Pratiquez le yoga, fitness ou le bien-être avec OLY Be dans le studio {placeTitle} avec nos meilleurs professeurs !
        `,
        description: 'Explore - Place: description',
      },
      {
        placeTitle: place.title,
      }
    ),
  }),
  [routes.explore.places]: (intl, { area }) => ({
    noindex: true,
    title: intl.formatMessage(
      {
        defaultMessage: 'Nos studios à {areaTitle} - OLY Be',
        description: 'Explore - Places: title',
      },
      {
        areaTitle: area.title,
      }
    ),
  }),
  [routes.explore.serie]: (intl, { serie }) => ({
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours de {serieTitle} - OLY Be',
        description: 'Explore - Serie: title',
      },
      {
        serieTitle: serie.title,
      }
    ),
    openGraph: {
      images: [
        {
          url: `${Config.websiteUrl}/api/ogs/${serie.ulid}?displayButton=0`,
        },
      ],
    },
  }),

  // EXPLORE STUDIO - By Url
  '/explore/studio/paris': (intl, { area }) => ({
    noindex: true,
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours en studio à {areaTitle} - OLY Be',
        description: 'Explore - Studio: title',
      },
      {
        areaTitle: area.title,
      }
    ),
    description: intl.formatMessage({
      defaultMessage: `
        Trouvez votre cours en studio à Paris : Yoga, Pilates, Fitness, Bien-être, Méditation... 50+ studios près
        de chez vous pour pratiquer facilement ! Découvrez tous les prochains cours disponibles sur notre planning.
      `,
      description: 'Explore - Studio Paris: desc',
    }),
    openGraph: {
      images: [
        {
          url: imgUrl('/pictures/explore-studio-paris.jpg', { w: 1200 }),
        },
      ],
    },
  }),
  '/explore/studio/lyon': (intl, { area }) => ({
    noindex: true,
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours en studio à {areaTitle} - OLY Be',
        description: 'Explore - Studio: title',
      },
      {
        areaTitle: area.title,
      }
    ),
    description: intl.formatMessage({
      defaultMessage: `
        Trouvez votre cours en studio à Lyon : Yoga, Pilates, Fitness, Bien-être, Méditation...Vos studios près de
        chez vous pour pratiquer facilement ! Découvrez tous les prochains cours disponibles sur notre planning.
      `,
      description: 'Explore - Studio Lyon: desc',
    }),
    openGraph: {
      images: [
        {
          url: imgUrl('/pictures/explore-studio-lyon.jpg', { w: 1200 }),
        },
      ],
    },
  }),
  // EXPLORE STUDIO - By NextRoute
  [routes.explore.studio]: (intl, { area }) => ({
    noindex: true,
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours en studio à {areaTitle} - OLY Be',
        description: 'Explore - Studio: title',
      },
      {
        areaTitle: area.title,
      }
    ),
  }),
}

const routesMe: ISeoDetailsList = {
  [routes.account.atwork]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mon espace entreprise - OLY Be',
      description: 'Account - AtWork: title',
    }),
  }),
  [routes.account.bookings]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes réservations - OLY Be',
      description: 'Account - Bookings: title',
    }),
  }),
  [routes.account.favorites.index]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes favoris - OLY Be',
      description: 'Account - Favorites: title',
    }),
  }),
  [routes.account.favorites.teachers]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes professeurs favoris - OLY Be',
      description: 'Account - Favorites - Teachers: title',
    }),
  }),
  [routes.account.favorites.replays]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes replays favoris - OLY Be',
      description: 'Account - Favorites - Replays: title',
    }),
  }),
  [routes.account.favorites.videos]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes vidéos favorites - OLY Be',
      description: 'Account - Favorites - Videos: title',
    }),
  }),
  [routes.account.favorites.videoGroups]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes programmes favoris - OLY Be',
      description: 'Account - Favorites - VideoGroups: title',
    }),
  }),
  [routes.account.favorites.studios]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes studios favoris - OLY Be',
      description: 'Account - Favorites - Places: title',
    }),
  }),
  [routes.account.favorites.series]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes séries favorites - OLY Be',
      description: 'Account - Favorites - Series: title',
    }),
  }),
  [routes.account.unlimitedPlaylist]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes programmes en accès illimité - OLY Be',
      description: 'Account - UnlimitedPlaylist: title',
    }),
  }),
  [routes.account.invoices]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes factures - OLY Be',
      description: 'Account - Invoices: title',
    }),
  }),
  [routes.account.prepaids]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes cartes cadeaux - OLY Be',
      description: 'Account - Prepaids: title',
    }),
  }),
  [routes.account.profile]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mon profil - OLY Be',
      description: 'Account - Profile: title',
    }),
  }),
  [routes.account.purchases]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes abonnements et cartes - OLY Be',
      description: 'Account - Purchases: title',
    }),
  }),
  [routes.account.referrals]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes parrainages - OLY Be',
      description: 'Account - Referrals: title',
    }),
  }),
  [routes.account.achievements]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Mes accomplissements - OLY Be',
      description: 'Account - Achievements: title',
    }),
  }),
}

const routesOrder: ISeoDetailsList = {
  [routes.order]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Initialisation de votre commande',
      description: 'Order: title',
    }),
  }),
}

const routesPayment: ISeoDetailsList = {
  [routes.payment]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Votre paiement - OLY Be',
      description: 'Payment: title',
    }),
  }),
}

const routesPlaylists: ISeoDetailsList = {
  [routes.playlist]: (intl, { videoGroup }) => ({
    title: intl.formatMessage(
      {
        defaultMessage: 'Programme : {videoGroupTitle} - OLY Be',
        description: 'Playlist: title',
      },
      {
        videoGroupTitle: videoGroup.title,
      }
    ),
    description: videoGroup.description,
  }),
  [routes.playlists]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Nos programmes vidéos - OLY Be',
      description: 'Playlists: title',
    }),
    description: intl.formatMessage({
      defaultMessage:
        'Découvrez tous nos programmes vidéos de cours de yoga, pilates et fitness en vidéos.',
      description: 'Playlists: description',
    }),
  }),
}

const routesPractice: ISeoDetailsList = {
  [routes.practice.studio]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Cours en studio à Paris et à Lyon - OLY Be',
      description: 'Practice - Studio: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Trouvez votre cours en studio à Paris et à Lyon : Yoga, Pilates, Fitness, Bien-être, Méditation...
        Vos studios près de chez vous pour pratiquer facilement ! Découvrez tous les prochains cours
        disponibles sur notre planning.
      `,
      description: 'Practice - Studio: description',
    }),
    openGraph: {
      images: [
        {
          url: imgUrl('/pictures/practice-studio.jpg', { w: 1200 }),
        },
      ],
    },
  }),
  [routes.practice.studioArea]: (intl, { area }) => ({
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours de yoga, fitness ou Bien-être en studio à {area} - OLY Be',
        description: 'Practice - Studio - Area: title',
      },
      { area: area.title }
    ),
    description: intl.formatMessage(
      {
        defaultMessage:
          'Découvrez nos cours de Yoga, fitness ou Bien-être en studio à {area}. Pratiquez facilement près de chez vous !',
        description: 'Practice - Studio - Area: description',
      },
      { area: area.title }
    ),
  }),
  [routes.practice.online.index]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Cours de Yoga en ligne : en Vidéos et en Live - OLY Be',
      description: 'Practice - Online: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Découvrez nos cours de Yoga, fitness ou Bien-être en ligne et en vidéos. Pratiquez depuis chez vous avec
        des cours en Live et en replay.
      `,
      description: 'Practice - Online: description',
    }),
  }),
  [routes.practice.online.live]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Cours de Yoga en live et replay - OLY Be',
      description: 'Practice - Online - Live: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Découvrez nos cours de Yoga, fitness ou Bien-être en live et en replay. Pratiquez depuis chez vous quand
        vous voulez.
      `,
      description: 'Practice - Online - Live: description',
    }),
  }),
  [routes.practice.online.videos]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Cours de Yoga en vidéo - OLY Be',
      description: 'Practice - Online - Videos: title',
    }),
    description: intl.formatMessage({
      defaultMessage:
        'Découvrez nos cours de Yoga, fitness ou Bien-être en vidéo. Pratiquez depuis chez vous quand vous voulez.',
      description: 'Practice - Online - Videos: description',
    }),
  }),
  [routes.practice.workshop]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Ateliers de yoga, fitness et bien-être en studio - OLY Be',
      description: 'Practice - Workshop: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Découvrez nos ateliers thématiques OLY Be en studio chaque semaine : yoga, fitness, bien-être... Profiter
        de nouveautés près de chez vous.
      `,
      description: 'Practice - Workshop: description',
    }),
  }),
}

const routesPricing: ISeoDetailsList = {
  [routes.pricing.online]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Tarifs des cours en ligne OLY Be',
      description: 'Pricing - Online: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Retrouvez tous les tarifs des cours en ligne OLY Be pour profiter de tous nos cours en vidéos et en live :
        Yoga, Pilates, Barre au sol, Sophro...
      `,
      description: 'Pricing - Online: description',
    }),
  }),
  [routes.pricing.prepaid]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Offrez une carte cadeau OLY Be',
      description: 'Pricing - Prepaid: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Offrez des cours de yoga, fitness et bien-être à votre entourage ! Valable pour nos cours en ligne ou en
        studio partout en France.
      `,
      description: 'Pricing - Prepaid: description',
    }),
  }),
  [routes.pricing.studio]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Tarifs des cours en studio OLY Be',
      description: 'Pricing - Studio: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Retrouvez tous les tarifs des cours en studio OLY Be, valables sur tous les cours de Yoga, fitness,
        Barre au sol, Sophro…
      `,
      description: 'Pricing - Studio: description',
    }),
  }),
}

const routesSession: ISeoDetailsList = {
  [routes.session.live]: (intl, { lesson }) => ({
    canonicalUri: lesson.serie
      ? convertUrlObjectToString(buildSerieExploreRoute(lesson.serie.ulid) as UrlObject)
      : null,
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours de {title} en live et replay avec {teacherName} - OLY Be',
        description: 'Session - Live: title',
      },
      {
        title: lesson.title,
        teacherName: lesson.teacher?.fullName ?? '-',
      }
    ),
    description: intl.formatMessage(
      {
        defaultMessage: `
          Pratiquez depuis chez vous, découvrez notre cours de {title} en live et replay avec {teacherName}. Prenez
          un moment pour vous en choisissant parmi notre sélection de cours de Yoga, Pilates, Fitness, Méditation ou
          Bien-être en live et en replay.
        `,
        description: 'Session - Live: description',
      },
      {
        title: lesson.title,
        teacherName: lesson.teacher?.fullName ?? '-',
      }
    ),
    openGraph: {
      images: [
        {
          url: `${Config.websiteUrl}/api/ogl/${lesson.uuid}?displayButton=0`,
        },
      ],
    },
  }),
  [routes.session.replay]: (intl, { lesson }) => ({
    canonicalUri: lesson.serie
      ? convertUrlObjectToString(buildSerieExploreRoute(lesson.serie.ulid) as UrlObject)
      : null,
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours de {title} en live et replay avec {teacherName} - OLY Be',
        description: 'Session - Replay: title',
      },
      {
        title: lesson.title,
        teacherName: lesson.teacher?.fullName ?? '-',
      }
    ),
    description: intl.formatMessage(
      {
        defaultMessage: `
          Pratiquez depuis chez vous, découvrez notre cours de {title} en live et replay avec {teacherName}. Prenez
          un moment pour vous en choisissant parmi notre sélection de cours de Yoga, Pilates, Fitness, Méditation ou
          Bien-être en live et en replay.
        `,
        description: 'Session - Replay: description',
      },
      {
        title: lesson.title,
        teacherName: lesson.teacher?.fullName ?? '-',
      }
    ),
    openGraph: {
      images: [
        {
          url: imgUrl('/pictures/og-live-replay.jpg', { w: 1200 }),
        },
      ],
    },
  }),
  [routes.session.studio]: (intl, { lesson }) => ({
    canonicalUri: lesson.serie
      ? convertUrlObjectToString(buildSerieExploreRoute(lesson.serie.ulid) as UrlObject)
      : null,
    title: intl.formatMessage(
      {
        defaultMessage: 'Cours de {title} au studio {studioTitle} avec OLY Be',
        description: 'Session - Studio: title',
      },
      {
        title: lesson.title,
        studioTitle: lesson.place?.title ?? '-',
      }
    ),
    description: intl.formatMessage(
      {
        defaultMessage: `
          Participez au cours de {title} avec {teacherName} chaque semaine ! RDV au
          studio {studioTitle} pour prendre un moment pour vous et rencontrer nos professeurs testés et certifiés.
        `,
        description: 'Session - Studio: description',
      },
      {
        title: lesson.title,
        teacherName: lesson.teacher?.fullName ?? '-',
        studioTitle: lesson.place?.title ?? '-',
      }
    ),
    openGraph: {
      images: [
        {
          url: `${Config.websiteUrl}/api/ogl/${lesson.uuid}?displayButton=0`,
        },
      ],
    },
  }),
}

const routesVideos: ISeoDetailsList = {
  [routes.video]: (intl, { video }) => ({
    title: intl.formatMessage(
      {
        defaultMessage: '{title}: cours en vidéo avec OLY Be',
        description: 'Video: title',
      },
      {
        title: video.title,
      }
    ),
    description: intl.formatMessage(
      {
        defaultMessage: `
          Découvrez notre vidéo {title} avec {teacherName} pour prendre soin de vous. Pratiquez depuis
          chez vous quand vous voulez, avec des centaines de vidéos en accès libre.
        `,
        description: 'Video: description',
      },
      {
        title: video.title,
        teacherName: video.teachedBy?.fullName ?? '-',
      }
    ),
    openGraph: {
      images: [
        {
          url: imgUrl('/pictures/og-video.jpg', { w: 1200 }),
        },
      ],
    },
  }),
  [routes.videos]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: 'Cours de Yoga, Pilates, Fitness et Bien-être en vidéo - OLY Be',
      description: 'Videos: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        Des centaines de vidéos de Yoga tous niveaux, du débutant au Yogi confirmé - Vinyasa, Hatha, Kundalini,
        Yin - du Fitness, du Bien-être et de la Méditation, avec des vidéos en accès libre
      `,
      description: 'Videos: description',
    }),
    openGraph: {
      images: [
        {
          url: imgUrl('/pictures/videos.jpg', { w: 1200 }),
        },
      ],
    },
  }),
}

const routesMisc: ISeoDetailsList = {
  '/': (intl) => ({
    title: intl.formatMessage({
      defaultMessage:
        'OLY Be : Yoga, Pilates #ForAll - Cours en studio et en ligne de Yoga et Pilates',
      description: 'Home: title',
    }),
    description: intl.formatMessage({
      defaultMessage: `
        OLY Be propose des cours de Yoga, Pilates, Fitness, Bien-être et Méditation. Cours en studio,
        en ligne (vidéos et live) ou dans votre entreprise. Ateliers, stages et formations diplômantes.
      `,
      description: 'Home: description',
    }),
    openGraph: {
      images: [
        {
          url: imgUrl('/pictures/home.jpg', { w: 1200 }),
        },
      ],
    },
  }),
  [routes.about]: (intl) => ({
    title: intl.formatMessage({
      defaultMessage: "Découvrez l'histoire et l'équipe OLY Be",
      description: 'About: title',
    }),
    description: intl.formatMessage({
      defaultMessage:
        "Découvrez l'histoire d'OLY Be et notre manifeste, rencontrez l'équipe et partagez le Yoga For All !",
      description: 'About: description',
    }),
  }),
  [routes.onboarding]: (intl) => ({
    noindex: true,
    title: intl.formatMessage({
      defaultMessage: 'Onboarding OLY Be',
      description: 'Onboarding: description',
    }),
  }),
  [routes.cgu]: (intl) => ({
    noindex: false,
    title: intl.formatMessage({
      defaultMessage: 'Mentions Légales - OLY Be',
      description: 'Cgu: description',
    }),
  }),
  ['/[prismicUid]']: (intl, { prismicDocument: { data: prismicDocumentData } }) => ({
    noindex: !prismicDocumentData.sitemap,
    title: prismicDocumentData.metaTitle ?? prismicDocumentData.title,
    description: prismicDocumentData.metaDescription,
    ...(prismicDocumentData.sitemap && {
      canonicalUri: prismicDocumentData.canonicalUri,
    }),
  }),
}

export const SEO_BYPATH: ISeoDetailsList = {
  ...routesAuth,
  ...routesAtWork,
  ...routesBlog,
  ...routesBooking,
  ...routesDashboard,
  ...routesExplore,
  ...routesMe,
  ...routesOrder,
  ...routesPayment,
  ...routesPlaylists,
  ...routesPractice,
  ...routesPricing,
  ...routesSession,
  ...routesVideos,
  ...routesMisc,
}
